// @ts-nocheck
import * as React from 'react';

import { http, setAuthorizationHeader } from '../../helpers/http';
import { apiUrl, localStorageUserKey } from '../../helpers/constants';
import { notification } from 'antd';
import jwt_decode from 'jwt-decode';

export type ContextType = {
  loading: boolean;
  ready: boolean;
  isConnected: boolean;
  user: {
    email: string;
    phone: string;
    uspi: string[];
    company: {
      name: string;
    };
    address: {
      street: string;
      number: string;
      npa: string;
      city: string;
      canton: string;
      country: string;
    };
    lastname: string;
    firstname: string;
    _id: string;
    role: 'user' | 'admin';
  };
  logUser: Function;
  logOut: Function;
  registerNewUser: Function;
};

const defaultContext = {};

export const AppContext = React.createContext<ContextType>(defaultContext);

const initialState = {
  loading: false,
  ready: false,
  isConnected: false,
};

export class AppProvider extends React.Component {
  async componentDidMount() {
    await this.authLocalUser();
  }

  init = async () => {
    // https://json-generator.com/

    // const units = await http.get(`/lots`);

    this.setState({
      ready: true,
    });
  };

  registerNewUser = async (data) => {
    try {
      const response = await http.post(`auth/signup`, data);
      return true;
    } catch (e) {
      return e.response.data.errors.message;
    }
  };

  updateUserContext = async () => {
    const response = await http.get(`users/${this.state.user._id}`);

    if (response.status === 200) {
      this.setState({
        user: response.data,
      });
    }
  };
  authLocalUser = async () => {
    try {
      const token = localStorage.getItem(localStorageUserKey);

      if (token !== null) {
        // invalid token
        var decoded = jwt_decode(token);

        http.defaults.headers.common.Authorization = 'Token ' + token;

        const response = await http.get(`users/${decoded.id}`);

        if (response.status === 200) {
          this.setState({
            token,
            user: response.data,
            isConnected: true,
            ready: true,
          });
        }
      } else {
        this.setState({
          ready: true,
        });
      }
    } catch (e) {
      delete http.defaults.headers.common.Authorization;
    }
  };

  logOut = async () => {
    await localStorage.clear();
    await this.setState({ ...initialState, ready: true });
  };

  logUser = async (email: string, pwd: string) => {
    try {
      console.log(email)
      const response = await http.post(`auth/login`, {
        email: email.trim(),
        password: pwd,
      });

      await localStorage.setItem(localStorageUserKey, response.data.user.token);

      const { user } = response.data;

      notification.success({
        message: `${'Bienvenue'} ${user.lastname} ${user.firstname} !`,
      });

      await this.authLocalUser();

      return response.data.user;
    } catch (e) {
      // console.log(e);
      if (e?.response.data.message !== undefined) {
        notification.error({
          message: e?.response.data.message,
        });
      } else {
        notification.error({
          message: `Ces identifiants ne sont pas corrects`,
        });
      }
    }
  };

  state = {
    ...initialState,
    logUser: this.logUser,
    logOut: this.logOut,
    registerNewUser: this.registerNewUser,
    updateUserContext: this.updateUserContext,
  };

  render() {
    // @ts-ignore
    const { children } = this.props;

    return (
      <AppContext.Provider value={this.state}>{children}</AppContext.Provider>
    );
  }
}

export const withApp =
  (Component: JSX.Element) => (props: JSX.ElementAttributesProperty) =>
    (
      <AppContext.Consumer>
        {(store) => <Component app={store} {...props} />}
      </AppContext.Consumer>
    );
