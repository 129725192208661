import { useAppDispatch, useAppSelector } from '../../engine/hooks/redux.hooks';
import _ from 'lodash';
import { SyncOutlined, DeleteOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';

import {
  Button,
  Layout,
  Table,
  theme,
  Typography,
  Select,
  Popconfirm,
  Empty,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { documentsReducer, getDocuments } from './Documents.slice';

import {
  FaFilePdf,
  FaFileWord,
  FaFileExcel,
  FaFilePowerpoint,
  FaFileImage,
  FaFileAlt,
  FaFile,
} from 'react-icons/fa';

import CustomForm from '../../components/CustomForm';
import moment from 'moment';
import { http } from '../../helpers/http';
import { statusOptionSimple } from '../../helpers/constants';
import { StickyWrapper } from '../../helpers/utils';
const { Content, Sider, Header, Footer } = Layout;

export function DocumentsList({ folder, title }) {
  const documents = useAppSelector((state) => state.documents);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // @ts-ignore
    dispatch(getDocuments(folder));
  }, []);

  const {
    token: { colorBgContainer, colorPrimary },
    token,
  } = theme.useToken();

  const FileIcon = (mimeType) => {
    const iconStyle = {
      fontSize: '24px', // define your desired icon size
      marginRight: '8px', // define any additional styles for spacing, etc.
      color: colorPrimary,
    };

    let icon = null;
    // Map mime types to corresponding icons
    switch (mimeType) {
      case 'application/pdf':
        icon = <FaFilePdf style={iconStyle} />;
        break;
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        icon = <FaFileWord style={iconStyle} />;
        break;
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        icon = <FaFileExcel style={iconStyle} />;
        break;
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        icon = <FaFilePowerpoint style={iconStyle} />;
        break;
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
      case 'image/bmp':
      case 'image/webp':
        icon = <FaFileImage style={iconStyle} />;
        break;
      default:
        icon = <FaFileAlt style={iconStyle} />;
        break;
    }

    return icon;
  };

  function bytesToMB(bytes) {
    const mb = bytes / (1024 * 1024);
    return mb.toFixed(2); // Return value rounded to 2 decimal places
  }

  const downloadDocument = async (file) => {
    const url = `documents/filename/${file.name}?folder=${file.folder}`;
    try {
      const response = await http({
        method: 'GET',
        url: url,
        responseType: 'blob',
      });
      if (response.status === 200) {
        // Check if response contains a PDF
        if (response.data.type === 'application/pdf') {
          // Convert blob to object URL
          const file = new Blob([response.data], { type: 'application/pdf' });
          const fileUrl = URL.createObjectURL(file);

          // Open file in new tab
          const link = document.createElement('a');
          link.href = fileUrl;
          link.target = '_blank';
          link.click();
        } else {
          // Trigger file download
          const downloadUrl = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', file.name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } else {
        throw new Error('Failed to download file');
      }
    } catch (error) {
      console.error(error);
      // Handle error
    }
  };

  const columns = [
    {
      title: 'Nom',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'Date',
      dataIndex: 'uploadDate',
      key: 'uploadDate',
      width: '15%',
      render: (r, t) => moment(r).format('LLLL'),
      sorter: (a, b) =>
        new Date(a.uploadDate).getTime() - new Date(b.uploadDate).getTime(),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: '40px',
      key: 'type',
      render: (type) => <>{FileIcon(type)}</>,
    },
    {
      title: 'Taille',
      width: '100px',
      dataIndex: 'size',
      render: (size) => <>{bytesToMB(size)} mb</>,
      sorter: (a, b) => {
        if (a.size < b.size) {
          return -1;
        }
        if (a.size > b.size) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'Lien',
      dataIndex: 'link',
      width: '15%',
      key: 'type',
      render: (type, file) => {
        return (
          <Button type={'primary'} onClick={() => downloadDocument(file)}>
            Télécharger
          </Button>
        );
      },
    },
  ];

  const inputs = [
    {
      parentLabel: null,
      children: [
        {
          label: 'Rechercher un fichier',
          name: 'name',
          value: '',
          type: 'input',
          capitalize: false,
          col: 12,
        },
      ],
    },
  ];

  const [searchName, setSearchName] = useState('');
  const onChangeData = (form, changedFields, allFields) => {
    setSearchName(form.getFieldValue('name'));
  };

  // Filter documents by name
  const filteredDocuments = documents.documents
    .map((document) => {
      if (searchName !== '') {
        let filtered = document.files.filter((file) => {
          return file.name.toLowerCase().includes(searchName.toLowerCase());
        });

        return { ...document, files: filtered };
      } else {
        return document;
      }
    })
    .filter((doc) => {
      return doc.files.length !== 0;
    });

  const sortFolderYearString = (a, b) => {
    const nameA = a?.name;
    const nameB = b?.name;
    const yearA = parseInt(nameA, 10);
    const yearB = parseInt(nameB, 10);

    // Check if both are years
    if (!isNaN(yearA) && !isNaN(yearB)) {
      return yearB - yearA;
    }

    // If nameA is a year and nameB is not
    if (!isNaN(yearA) && isNaN(yearB)) {
      return -1;
    }

    // If nameB is a year and nameA is not
    if (isNaN(yearA) && !isNaN(yearB)) {
      return 1;
    }

    // If neither are years, compare them as strings
    if (nameA && nameB) {
      return nameB.localeCompare(nameA);
    }

    // At this point, handle cases where one or both names are undefined or null
    if (!nameA && nameB) return 1;
    if (nameA && !nameB) return -1;
    return 0;
  };

  return (
    <>
      <Helmet>
        <title>Extranet USPI - Documents</title>
      </Helmet>
      <StickyWrapper>
        <Header
          style={{
            height: '80px',
            padding: 20,
            background: colorBgContainer,
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography.Title
            style={{
              margin: 15,
              color: ' rgb(0 21 41)',
              fontWeight: 300,
            }}
            className={''}
            level={1}
          >
            {title}
          </Typography.Title>
          {/*<div style={{ flexDirection: 'row', display: 'flex' }}>*/}
          {/*  <Popconfirm*/}
          {/*    title="Confirmer votre action ?"*/}
          {/*    onOpenChange={() => console.log('open change')}*/}
          {/*  >*/}
          {/*    <Button*/}
          {/*      style={{ justifySelf: 'flex-start', display: 'flex' }}*/}
          {/*      loading={documents.loading}*/}
          {/*      disabled={documents.loading}*/}
          {/*      icon={<DeleteOutlined style={{ marginTop: '5px' }} />}*/}
          {/*    >*/}
          {/*      Action*/}
          {/*    </Button>*/}
          {/*  </Popconfirm>*/}
          {/*</div>*/}
        </Header>
      </StickyWrapper>
      <Content
        style={{
          margin: '24px 16px 0',
          overflow: 'initial',
        }}
      >
        <div
          style={{
            padding: 24,
            background: colorBgContainer,
          }}
        >
          <CustomForm
            dataSource={inputs}
            labelCol={24}
            wrapperCol={24}
            onChangeData={onChangeData}
            handleSubmit={undefined}
            resetOnFinish={undefined}
            refForm={undefined}
            onValuesChange={undefined}
          />

          {filteredDocuments.sort(sortFolderYearString).map((folder) => {
            return (
              <>
                <Typography.Title
                  style={{
                    marginTop: 10,
                    fontWeight: 300,
                    fontSize: '25px',
                    color: colorPrimary,
                    textTransform: 'uppercase',
                  }}
                  className={''}
                >
                  {folder.name}
                </Typography.Title>
                <Table
                  loading={documents.loading}
                  pagination={{
                    hideOnSinglePage: true,
                    position: ['bottomRight'],
                    defaultPageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100],
                    showSizeChanger: true,
                  }}
                  dataSource={folder.files}
                  columns={columns}
                  rowKey="_id"
                />
              </>
            );
          })}

          {documents.documents.length === 0 && (
            <Empty description={'Aucun dossier trouvé'} />
          )}
        </div>
      </Content>
    </>
  );
}
