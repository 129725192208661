// @ts-nocheck

import {
  Button,
  Layout,
  Popconfirm,
  Skeleton,
  Space,
  Table,
  Tag,
  theme,
  Typography,
} from 'antd';

import { Bar, Doughnut } from 'react-chartjs-2';

import { Chart, registerables } from 'chart.js';

// OR

import React, { useContext, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../engine/hooks/redux.hooks';
import { AppContext, ContextType } from '../../engine/contexts/App.context';
import { DeleteOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Card, Col, Row, Statistic } from 'antd';
import { Helmet } from 'react-helmet';

import CustomForm from '../../components/CustomForm';
import { getStats } from './Dashboard.slice';
import { GrValidate } from 'react-icons/gr';
import { FaUsers } from 'react-icons/fa';
import { HiOutlineUserGroup, HiOutlineClipboardList } from 'react-icons/hi';
import { MdRealEstateAgent } from 'react-icons/md';
import { listCantons, renderUspi, StickyWrapper } from '../../helpers/utils';
import moment from 'moment/moment';
const { Content, Sider, Header, Footer } = Layout;

Chart.register(...registerables);
export function Dashboard({}) {
  const { logOut, user } = useContext<ContextType>(AppContext);

  const { statistics, loading } = useAppSelector((state) => state.dashboard);

  const dispatch = useAppDispatch();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    // @ts-ignore
    dispatch(getStats());
  }, []);

  const {
    token: { colorPrimary },
    token,
  } = theme.useToken();

  const labels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
  ];

  const data = {
    labels: statistics?.uspiByCount?.map((u) => {
      return listCantons.find((e) => e.value === u.value).label;
    }),
    datasets: [
      {
        label: ' USPI',
        data: statistics?.uspiByCount?.map((w) => w.count),
        backgroundColor: ['#eee', '#d53535'],
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>Extranet USPI - Tableau de bord</title>
        <meta name="description" content="Description of PublicLogin" />
      </Helmet>
      <StickyWrapper>
        <Header
          style={{
            padding: 10,
            background: colorBgContainer,
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography.Title
            style={{
              marginBottom: 0,
              marginLeft: '20px',
              color: ' rgb(0 21 41)',
              fontWeight: 300,
              fontSize: '25px',
            }}
            className={''}
          >
            Tableau de bord
          </Typography.Title>
        </Header>
      </StickyWrapper>
      <Content
        style={{
          margin: '20px',
          overflow: 'initial',
        }}
      >
        {/*
             res.send({
      inactiveUserCount,
      cahiersExportedCount: exportCount,
      latestDocuments: latest,
      cahierCreatedThisMonth,
      totalGerance,
      totalCahiers,
      lastRegisteredUsers,
      totalUsers,
      uspiByCount,
      documentCount,
    });

                    // data={[
                    //   { title: 'One', value: 10, color: '#E38627' },
                    //   { title: 'Two', value: 15, color: '#C13C37' },
                    //   { title: 'Three', value: 20, color: '#6A2135' },
                    // ]}
            */}
        <Row gutter={[15, 15]}>
          <Col span={24}>
            <Card bordered={true} loading={loading}>
              <Typography.Title
                style={{
                  margin: 0,
                  color: ' rgb(0 21 41)',
                  fontWeight: 300,
                  fontSize: '25px',
                }}
                className={''}
              >
                Bonjour {user.firstname} {user.lastname}
              </Typography.Title>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              bordered={false}
              loading={loading}
              title={'Derniers documents assemblées ajoutés'}
            >
              <Table
                showHeader={false}
                pagination={false}
                dataSource={statistics?.latestDocuments.meeting}
                columns={[
                  {
                    title: 'Nom',
                    dataIndex: 'fileName',
                    key: 'fileName',
                    width: '50%',
                  },
                  {
                    title: 'Date',
                    dataIndex: 'dateAdded',
                    key: 'dateAdded',
                    width: '50%',
                    render: (r, t) => moment(r).format('LLLL'),
                  },
                ]}
              ></Table>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              bordered={false}
              showHeader={false}
              loading={loading}
              title={'Derniers documents  utiles ajoutés'}
            >
              <Table
                showHeader={false}
                pagination={false}
                dataSource={statistics?.latestDocuments.utils}
                columns={[
                  {
                    title: 'Nom',
                    dataIndex: 'fileName',
                    key: 'fileName',
                    width: '50%',
                  },
                  {
                    title: 'Date',
                    dataIndex: 'dateAdded',
                    key: 'dateAdded',
                    width: '50%',
                    render: (r, t) => moment(r).format('LLLL'),
                  },
                ]}
              ></Table>
            </Card>
          </Col>
          {user.uspi.find((e) => e === 'fr') !== undefined ||
          user.role === 'admin' ? (
            <Col span={12}>
              <Card
                bordered={false}
                showHeader={false}
                loading={loading}
                title={'Derniers documents  Fribourg ajoutés'}
              >
                <Table
                  showHeader={false}
                  pagination={false}
                  dataSource={statistics?.latestDocuments.fribourg}
                  columns={[
                    {
                      title: 'Nom',
                      dataIndex: 'fileName',
                      key: 'fileName',
                      width: '50%',
                    },
                    {
                      title: 'Date',
                      dataIndex: 'dateAdded',
                      key: 'dateAdded',
                      width: '50%',
                      render: (r, t) => moment(r).format('LLLL'),
                    },
                  ]}
                ></Table>
              </Card>
            </Col>
          ) : null}
          <Col span={12}>
            <Card
              bordered={false}
              showHeader={false}
              loading={loading}
              title={'Derniers documents  USPi infos ajoutés'}
            >
              <Table
                showHeader={false}
                pagination={false}
                dataSource={statistics?.latestDocuments.uspi}
                columns={[
                  {
                    title: 'Nom',
                    dataIndex: 'fileName',
                    key: 'fileName',
                    width: '50%',
                  },
                  {
                    title: 'Date',
                    dataIndex: 'dateAdded',
                    key: 'dateAdded',
                    width: '50%',
                    render: (r, t) => moment(r).format('LLLL'),
                  },
                ]}
              ></Table>
            </Card>
          </Col>
          {user.role === 'admin' && (
            <>
              <Col span={24}>
                <Typography.Title
                  style={{
                    marginTop: 10,
                    fontWeight: 300,
                    fontSize: '25px',
                    color: colorPrimary,
                    textTransform: 'uppercase',
                  }}
                  className={''}
                >
                  Informations administrateur
                </Typography.Title>
              </Col>
              <Col span={12}>
                <Card
                  bordered={false}
                  loading={loading}
                  title={'Actions requises'}
                >
                  <Statistic
                    title="Compte utilisateur à valider"
                    value={statistics?.inactiveUserCount}
                    valueStyle={{ color: '#3f8600' }}
                    prefix={<GrValidate color={'#3f8600'} />}
                    suffix=""
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card
                  bordered={false}
                  loading={loading}
                  title={'Utilisation des cahiers'}
                >
                  <Space direction={'horizontal'}>
                    <Statistic
                      style={{ minWidth: '150px' }}
                      title="Cahiers crées ce mois"
                      value={statistics?.cahierCreatedThisMonth}
                      valueStyle={{ color: '#cf1322' }}
                      prefix={<ArrowUpOutlined />}
                    />
                    <Statistic
                      style={{ minWidth: '150px' }}
                      title="Cahiers exportés ce mois (pdf)"
                      value={statistics?.cahiersExportedCount}
                      valueStyle={{ color: '#cf1322' }}
                      prefix={<ArrowUpOutlined />}
                    />
                  </Space>
                </Card>
              </Col>
              <Col span={12}>
                <Card
                  bordered={false}
                  showHeader={false}
                  loading={loading}
                  title={'Derniers utilisateurs créés'}
                >
                  <Table
                    showHeader={false}
                    pagination={false}
                    dataSource={statistics?.lastRegisteredUsers}
                    columns={[
                      {
                        title: 'Nom',
                        dataIndex: 'fileName',
                        key: 'n',
                        render: (n, i) => (
                          <>
                            {i.lastname} {i.firstname}
                          </>
                        ),
                      },
                      {
                        title: 'USPI',
                        dataIndex: 'uspi',
                        key: 'uspi',
                        render: (uspi, u) => {
                          if (uspi !== null) {
                            return renderUspi(uspi)?.map((e) => (
                              <Tag
                                color={'red'}
                                style={{ marginBottom: '4px' }}
                              >
                                {e}
                              </Tag>
                            ));
                          } else {
                            return null;
                          }
                        },
                      },
                      {
                        title: 'Date',
                        dataIndex: 'date_registration',
                        key: 'date_registration',
                        render: (r, t) => moment(r).format('LLLL'),
                      },
                    ]}
                  ></Table>
                </Card>
              </Col>

              <Col span={12}>
                <Card
                  bordered={false}
                  title={"Statistiques de l'extranet"}
                  loading={loading}
                >
                  <Space direction={'horizontal'}>
                    <Statistic
                      style={{ minWidth: '150px' }}
                      title="Utilisateurs"
                      value={statistics?.totalUsers}
                      valueStyle={{ color: '#3f8600' }}
                      prefix={<HiOutlineUserGroup />}
                      suffix=""
                    />
                    <Statistic
                      style={{ minWidth: '150px' }}
                      title="Gérances"
                      value={statistics?.totalGerance}
                      valueStyle={{ color: '#3f8600' }}
                      prefix={<MdRealEstateAgent />}
                      suffix=""
                    />
                    <Statistic
                      style={{ minWidth: '150px' }}
                      title="Cahiers créés"
                      value={statistics?.totalCahiers}
                      valueStyle={{ color: '#3f8600' }}
                      prefix={<HiOutlineClipboardList />}
                      suffix=""
                    />
                    <Statistic
                      style={{ minWidth: '150px' }}
                      title="Nombre de documents"
                      value={statistics?.documentCount}
                      valueStyle={{ color: '#3f8600' }}
                      prefix={<ArrowUpOutlined />}
                      suffix=""
                    />
                  </Space>
                </Card>
              </Col>
              <Col span={8}>
                <Card bordered={false} title={'USPIs'} loading={loading}>
                  <Doughnut data={data} />
                  {/*data={statistics?.uspiByCount.map((usp) => {*/}
                  {/*  return {*/}
                  {/*     .label,*/}
                  {/*    color: listCantons.find((e) => e.value === usp.value)*/}
                  {/*      .color,*/}
                  {/*   value: usp.count,*/}
                  {/*  };*/}
                  {/*})}*/}
                </Card>
              </Col>
            </>
          )}
        </Row>
      </Content>
    </>
  );
}
