// @ts-nocheck

import React, { useContext, useState } from 'react';
import ReactDOM from 'react-dom';
import { Form, Input, Button, Checkbox, Space, Card, notification } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './Login.css';
import Background_login from '../../../images/realestate-back.jpeg';
import Logo from '../../../assets/logo_uspi_suisse.svg';
import { AppContext } from '../../../engine/contexts/App.context';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CustomForm from '../../../components/CustomForm';
import { http } from '../../../helpers/http';

export const PasswordLost = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (data) => {
    setLoading(true);

    let send = await http.post('/auth/lost', data);

    notification.success({
      message: `Demande de mot de passe`,
      description:
        'Si votre adresse e-mail figure dans notre système, vous recevrez un e-mail contenant les instructions.',
    });
    setLoading(false);
  };

  const inputs = [
    {
      parentLabel: 'Renseigner votre email',
      children: [
        {
          label: 'Email',
          placeholder: 'Email',
          name: 'email',
          normalize: (value) => (value || '').toLowerCase().trim(),
          type: 'email',
          required: true,
          col: 24,
        },
      ],
    },
    {
      children: [
        {
          label: 'Envoyer la demande',
          name: 'send',
          value: 'send',
          type: 'submit',
          col: 24,
          align: 'left',
        },
      ],
    },
  ];

  return (
    <div
      className={'login-back'}
      style={{ backgroundImage: `url(${Background_login})` }}
    >
      <Helmet>
        <title>Extranet USPI - Mot de passe perdu</title>
      </Helmet>
      <div className={'login-container'}>
        <div className={'login-box'}>
          <img src={Logo} className={'login-logo'} />
          <CustomForm
            dataSource={inputs}
            labelCol={24}
            wrapperCol={24}
            handleSubmit={handleSubmit}
            resetOnFinish={undefined}
            refForm={undefined}
            onValuesChange={undefined}
          />
        </div>
      </div>
    </div>
  );
};
