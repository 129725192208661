'use client';

import { Button, notification } from 'antd';
import React from 'react';
import { IconErrorSvg } from './Iconsvg';
import { ErrorBoundary, ErrorBoundaryContext } from './Error.class';
import _ from 'lodash';
import { store } from '../../engine/store';
import { http } from '../../helpers/http';

export function Fallback({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ textAlign: 'center' }}>
        <div
          className="ant-result-icon ant-result-image"
          style={{
            marginTop: '100px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            justifyItems: 'center',
          }}
        >
          <h3>Oupss..Une erreur est survenue.</h3>
          <IconErrorSvg />
          <Button
            type={'primary'}
            onClick={() => {
              localStorage.clear();
              window.location.href = '/';
            }}
            style={{ marginBottom: 20, textAlign: 'center', background: 'red' }}
          >
            Revenir à la page d'accueil
          </Button>
        </div>
      </div>
    </div>
  );
}

export const ErrorWrapper = ({ children }) => {
  const logError = async (error, info) => {
    const state = store.getState();

    const currentURL = window.location.href;

    const errorLog = {
      stack: error.stack,
      state: state,
      baseUrl: currentURL,
    };

    let sender = await http.post('/errors', errorLog);

    notification.error({
      message:
        'Navré du dérangement, nous allons traiter votre erreur au plus vite.',
    });
  };

  return (
    <ErrorBoundary
      FallbackComponent={Fallback}
      onError={logError}
      onReset={(details) => {}}
    >
      {children}
    </ErrorBoundary>
  );
};
