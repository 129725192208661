// @ts-nocheck

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { http } from '../../helpers/http';
import { notification } from 'antd';
import { DocumentType } from '../helpers/Document.interface';
import { AppDispatch } from '../../engine/store';

interface DocumentsState {
  documents: DocumentType[];
  loading: boolean;
  value: number;
}

const initialState: DocumentsState = {
  documents: [],
  loading: false,
  value: 0,
};

interface typeAction {
  returnType: object[];
  folder: string;
}
export const getDocuments = createAsyncThunk<typeAction>(
  'documents',
  async (folder, thunkAPI) => {
    const res = await http.get(`/documents/listfilesname?folder=${folder}`);
    return res.data;
  },
);

export const createUnits = createAsyncThunk(
  'units/CreateUnits',
  async (units: [], thunkAPI) => {
    const res = await http.post('/units', { units });
    thunkAPI.dispatch(getPosts());
    return res.data;
  },
);

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {},
  extraReducers: {
    [getDocuments.pending]: (state) => {
      state.loading = true;
    },
    [getDocuments.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.documents = payload;
    },
  },
});

export const {} = documentsSlice.actions;

export const documentsReducer = documentsSlice.reducer;
