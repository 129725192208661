import { Button, Layout, theme, Typography } from 'antd';
import React from 'react';
import Error404 from './404 Error-pana.svg';
import { useNavigate } from 'react-router-dom';
const { Content, Sider, Header, Footer } = Layout;

export function NoMatch404() {
  const navigate = useNavigate();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100vh', // set height to 100vh for full viewport height
        display: 'flex',
        justifyContent: 'center', // center horizontally
        alignItems: 'center', // center vertically
      }}
    >
      <Content
        style={{
          margin: '24px 16px 0',
          overflow: 'initial',
          cursor: 'pointer',
          textAlign: 'center',
        }}
      >
        <img src={Error404} width={400} />
        <p>Erreur 404 la page demandée n'existe pas.</p>
        <Button onClick={() => navigate('/')} type="primary">
          Retourner sur le site
        </Button>
      </Content>
    </div>
  );
}
