// @ts-nocheck

import { configureStore } from '@reduxjs/toolkit';
import { unitsReducer } from '../pages/Units/UnitsList/Units.slice';
import { unitReducer } from '../pages/Units/UnitDetail/Unit.slice';
import { documentsReducer } from '../pages/Documents/Documents.slice';
import { userReducer } from '../pages/User/User.slice';
import { usersReducer } from '../pages/Users/Users.slice';
import { cahiersReducer } from '../pages/Cahiers/Cahiers.slice';
import { companiesReducer } from '../pages/Companies/Companies.slice';
import { companyReducer } from '../pages/Company/Company.slice';
import { cahierModelReducer } from '../pages/CahierModel/CahierModel.slice';
import { dashboardReducer } from '../pages/dashboard/Dashboard.slice';

// @ts-ignore
export const store = configureStore({
  reducer: {
    units: unitsReducer,
    unit: unitReducer,
    documents: documentsReducer,
    user: userReducer,
    users: usersReducer,
    cahiers: cahiersReducer,
    companies: companiesReducer,
    company: companyReducer,
    cahierModel: cahierModelReducer,
    dashboard: dashboardReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
