// @ts-nocheck

import React, { useState } from 'react';
import { Checkbox, Col, Input, Radio, Select } from 'antd';
import { debounce } from 'lodash';
const CahierSubChapterModelEntry = React.memo(
  ({
    entry,
    onChangeEntryShwon,
    renderCharge,
    onChangeValue,
    defaultValueNote,
    levels,
    renderStatus,
  }) => {
    // Component code for rendering an individual entry...

    const [inputValue, setInputValue] = useState(entry.note);
    const debouncedOnChange = debounce((value) => {
      onChangeValue({
        levels,
        type: 'note',
        value,
      });
    }, 300); // Adjust the debounce delay (in milliseconds) as needed

    const handleInputChange = (event) => {
      const value = event.target.value;
      setInputValue(value);
      debouncedOnChange(value);
    };

    return [
      <Col span={8} key={entry.name} style={{ marginLeft: '0px' }}>
        {entry.name}
      </Col>,
      <Col span={6}>
        <Radio.Group
          optionType="button"
          buttonStyle="solid"
          name="radiogroup"
          defaultValue={entry.status}
          size={'small'}
          onChange={(value) =>
            onChangeValue({
              levels,
              type: 'status',
              value: value.target.value,
            })
          }
        >
          <Radio className={'included'} value={'included'}>
            Incluse
          </Radio>
          <Radio className={'addition'} value={'addition'}>
            En Sus
          </Radio>
          <Radio className={'unrealised'} value={'unrealised'}>
            Non réalisée
          </Radio>
        </Radio.Group>
      </Col>,
      <Col span={4}>
        <Select
          allowClear
          placeholder={'A la charge'}
          size={'small'}
          defaultValue={entry.charge || null}
          style={{ width: ' 100%' }}
          onChange={(value) =>
            onChangeValue({
              levels,
              type: 'charge',
              value: value,
            })
          }
        >
          <Select.Option value={'owner'}>
            A charge du propriétaire
          </Select.Option>
          <Select.Option value={'tenant'}>A charge du locataire</Select.Option>
        </Select>
      </Col>,
      <Col span={4}>
        <Input
          allowClear
          placeholder={'Montant'}
          defaultValue={entry.note}
          onChange={handleInputChange}
          size={'small'}
          style={{ width: '100%' }}
        />
      </Col>,
      <Col span={2}>
        <Select
          allowClear
          placeholder={'Valeur'}
          defaultValue={entry.extra || null}
          size={'small'}
          style={{ width: ' 100%' }}
          onChange={(value) =>
            onChangeValue({
              levels,
              type: 'extra',
              value: value,
            })
          }
        >
          <Select.Option value={'CHF'}>CHF</Select.Option>
          <Select.Option value={'%'}>%</Select.Option>
        </Select>
      </Col>,
    ];
  },
);

export const MemoizedCahierSubChapterModel = React.memo(
  CahierSubChapterModelEntry,
  (prevProps, nextProps) => {
    // Custom comparison function for memoization
    return (
      prevProps.entry.status !== nextProps.entry.status ||
      prevProps.entry.levels !== nextProps.entry.levels ||
      prevProps.entry.note !== nextProps.entry.note ||
      prevProps.entry.charge !== nextProps.entry.charge ||
      prevProps.numerotationAutomatic !== nextProps.numerotationAutomatic ||
      prevProps.entry.extra !== nextProps.entry.extra
    );
  },
);
