/**
 *
 * SelectFieldAxios
 *
 */

import React, { memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Col, Form } from 'antd';

import useAsync from './useAsync';

// styles

// antd component
import { Select, Skeleton } from 'antd';
import ColFormItemWrapper from '../CustomForm/ColWFormItemWrapper';
import SelectCustom from '../CustomForm/SelectCustom';
import { http } from '../../helpers/http';

const { Option } = Select;

// icons
//import CustomIcons from 'components/CustomIcons';

function SelectFieldAxios({
  endPoint,
  itemCallback,
  itemsCallBack,
  filterItem = () => {
    return true;
  },
  item,
  onSelect,
  refForm,
  displayLabel,
  className,
  updateEndPoint,
  ready = true,
}) {
  const childClassNames = classNames('select-axios-wrapper', className);

  const { isLoading, isSuccess, run, data } = useAsync();
  const [randomKey, setRandomKey] = useState(null);

  const [listOptions, setListOptions] = useState([]);
  const [readyToRender, setReadyToRender] = useState(false);

  const doTheRun = () => {
    const { method = 'GET' } = item;
    if (method === 'GET') {
      run(
        http.get(endPoint).then((res) => {
          return res.data.filter(filterItem);
        }),
      );
    } else if (method === 'POST') {
      run(
        http.post(endPoint, item.body).then((res) => {
          return res.data.filter(filterItem);
        }),
      );
    }
  };

  /**
   * Loading data for rendering in a component
   * - work like ComponentDidUpdate
   */
  useEffect(() => {
    if (endPoint && ready && listOptions.length === 0) {
      doTheRun();
    }
  }, [endPoint, ready]);

  /**
   * Loading data for rendering in a component
   * - work like ComponentDidUpdate
   */
  useEffect(() => {
    if (data) {
      itemsCallBack(data);
      const optioins = data.map((elem) => itemCallback(elem));
      setListOptions(optioins);
      setReadyToRender(true);
    }
  }, [data]);

  // This hook is used to update the list on component when a variable is triggered (like from parent)
  useEffect(() => {
    if (updateEndPoint.length > 0 && ready) {
      doTheRun();
      setRandomKey(Math.random());
    }
  }, updateEndPoint);

  let extra = {};

  if (isLoading) {
    return (
      <Col style={{}} key={Math.random() + '-col'} span={12}>
        <Form.Item label={item.label}>
          <Skeleton.Input style={{ width: '100%' }} />
        </Form.Item>
      </Col>
    );
  }

  return (
    <>
      {isSuccess || ready === false ? (
        <ColFormItemWrapper item={item} key={randomKey}>
          {item.multiple ? (
            <SelectCustom
              ready={item.ready}
              item={item}
              refForm={refForm}
              renderExtraSelect={item.multiple}
              listOptions={listOptions}
              autoFocus={true}
              dropdownMatchSelectWidth={false}
              placeholder={
                item.placeholder !== undefined ? item.placeholder : item.label
              }
              allowClear={!!item.allowClear}
              disabled={item.disabled}
              optionFilterProp="children"
              showSearch
              mode={item.multiple ? 'multiple' : 'tag'}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              {...extra}
            />
          ) : (
            <Select
              dropdownMatchSelectWidth={false}
              placeholder={
                item.placeholder !== undefined ? item.placeholder : item.label
              }
              allowClear={item.allowClear ? true : false}
              disabled={item.disabled}
              optionFilterProp="children"
              onSelect={onSelect}
              showSearch
              mode={item.multiple ? 'multiple' : 'tag'}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              {...extra}
            >
              {listOptions.map((option, i) => (
                <Option
                  {...option}
                  key={option?.key || option?.value}
                  value={option?.value || option?.key}
                  color={option?.color}
                >
                  {option?.label || option?.value}
                </Option>
              ))}
            </Select>
          )}
        </ColFormItemWrapper>
      ) : null}
    </>
  );
}

SelectFieldAxios.propTypes = {};

SelectFieldAxios.defaultProps = {
  item: { itemsCallBack: () => {} },
  updateEndPoint: [],
};

export default memo(SelectFieldAxios);
