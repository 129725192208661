import { AppProvider } from './engine/contexts/App.context';
import 'antd/dist/reset.css';
import Routing from './engine/routeur/Routing';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { store } from './engine/store';
import './layouts/styles/App.css';
import './layouts/styles/index.css';
import { LoadingIndex } from './layouts/LoadingIndex';
import frFR from 'antd/locale/fr_FR';

import 'dayjs/locale/fr-ch';
import { ErrorWrapper } from './components/ErrorReportingBoundary';

function App() {
  return (
    <div className="App">
      <ErrorWrapper>
        <AppProvider>
          <Provider store={store}>
            <ConfigProvider
              locale={frFR}
              theme={{
                token: {
                  controlHeight: 40,
                  colorPrimary: '#D90416',
                  colorTextSecondary: '#262626',
                  colorTextBase: '#262626',
                  colorText: '#262626',
                },
              }}
            >
              <LoadingIndex />
            </ConfigProvider>
          </Provider>
        </AppProvider>
      </ErrorWrapper>
    </div>
  );
}

export default App;
