import React, { useContext, useState } from 'react';
import ReactDOM from 'react-dom';
import { Form, Input, Button, Checkbox, Space } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './Login.css';
import Background_login from '../../../images/realestate-back.jpeg';
import Logo from '../../../assets/logo_uspi_suisse.svg';
import { AppContext } from '../../../engine/contexts/App.context';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export const Login = () => {
  const { logUser } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    const log = await logUser(values.username.toLowerCase(), values.password);
    if (log) {
      navigate('/');
    }
  };

  return (
    <div
      className={'login-back'}
      style={{ backgroundImage: `url(${Background_login})` }}
    >
      <Helmet>
        <title>Extranet USPI - authentification</title>
      </Helmet>
      <div className={'login-container'}>
        <div className={'login-box'}>
          <img src={Logo} className={'login-logo'} />
          <h4>Extranet</h4>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            style={{ width: 'calc(100% - 20px)' }}
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              normalize={(value) => (value || '').toLowerCase().trim()}
              rules={[
                {
                  required: true,
                  message: "Veuillez saisir votre nom d'utilisateur !",
                },
              ]}
            >
              <Input
                type={'email'}
                size={'large'}
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Veuillez saisir votre mot de passe !',
                },
              ]}
            >
              <Input
                size={'large'}
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Mot de passe"
              />
            </Form.Item>
            <Form.Item>
              {/*<Form.Item name="remember" valuePropName="checked" noStyle>*/}
              {/*  <Checkbox>Remember me</Checkbox>*/}
              {/*</Form.Item>*/}

              <Link className="login-form-forgot" to="/auth/lost">
                Mot de passe perdu
              </Link>
            </Form.Item>
            <Form.Item>
              <Button
                style={{ width: '100%', marginBottom: '10px' }}
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Connectez-vous
              </Button>
              {/*<Button*/}
              {/*  style={{ width: 'calc(50% - 10px)' }}*/}
              {/*  onClick={() => navigate('/auth/register')}*/}
              {/*  className="login-form-button"*/}
              {/*>*/}
              {/*  S'inscrire*/}
              {/*</Button>*/}
              <a
                href={'mailto:info@uspi-suisse.ch'}
                style={{ width: 'calc(50%)', marginLeft: '10px' }}
              >
                J'ai besoin d'aide
              </a>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
