// @ts-nocheck

import { useAppDispatch, useAppSelector } from '../../engine/hooks/redux.hooks';
import _ from 'lodash';
import {
  SyncOutlined,
  DeleteOutlined,
  FileAddOutlined,
  RightCircleOutlined,
  CopyOutlined,
  FilePdfOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Helmet } from 'react-helmet';

import {
  Button,
  Popover,
  Layout,
  Table,
  theme,
  Typography,
  Select,
  Popconfirm,
  Tag,
  Card,
  Space,
  Modal,
  Radio,
  Checkbox,
} from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  colors,
  formatCurrency,
  statusOption,
  statusOptionSimple,
} from '../../helpers/constants';
import CustomForm from '../../components/CustomForm';
import { AppContext, ContextType } from '../../engine/contexts/App.context';
import { renderUspi, StickyWrapper } from '../../helpers/utils';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import {
  deleteDocument,
  duplicate,
  getCahiers,
  getTemplates,
  newCahier,
  newTemplate,
} from './Cahiers.slice';
import { Template, TemplateExemple } from '../../helpers/Cahier.interface';
import moment from 'moment';
import useCahierPDF from '../CahierModel/Cahier.pdf';
const { Content, Sider, Header, Footer } = Layout;

export function CahiersList() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenCahier, setIsModalOpenCahier] = useState(false);
  const [confirmOk, setConfirmOk] = useState(false);
  const formCreateModel = useRef(null);
  const formCreatecahier = useRef(null);

  const navigate: NavigateFunction = useNavigate();

  const { getCahier, loadingPdf } = useCahierPDF();

  const { user } = useContext<ContextType>(AppContext);

  const { cahiers, loadingCahiers, loadingTemplates, templates } =
    useAppSelector((state) => state.cahiers);
  const dispatch = useAppDispatch();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [searchName, setSearchName] = useState('');
  const [searchStatus, setSearchStatus] = useState('');

  useEffect(() => {
    // @ts-ignore
    dispatch(getCahiers(user._id));
    // @ts-ignore
    dispatch(getTemplates(user._id));
  }, []);

  const columns = [
    {
      title: 'Nom',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Date de création',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (createdDate) => moment(createdDate).format('LLLL'),
    },

    {
      title: 'Action',
      dataIndex: '_id',
      width: '15%',
      render: (_id, record) => {
        return (
          <Space>
            <Button onClick={() => navigate(`/models/${_id}`)} size={'small'}>
              <RightCircleOutlined />
              Détail et édition
            </Button>
            <Button
              size={'small'}
              onClick={() => {
                dispatch(
                  duplicate({ userId: user._id, type: 'model', id: _id }),
                );
              }}
            >
              <CopyOutlined />
              Dupliquer
            </Button>
            <Popconfirm
              okButtonProps={{
                disabled: !confirmOk,
              }}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              okText={'Supprimer'}
              title="Êtes-vous sûr de vouloir supprimer ce modèle et tous les cahiers liés ? "
              description={
                <>
                  <p>Cette action est irréversible.</p>
                  <label>
                    J'ai connaissance de mon action{' '}
                    <Checkbox
                      checked={confirmOk}
                      onChange={() => setConfirmOk(!confirmOk)}
                    />
                  </label>
                </>
              }
              onConfirm={() => {
                dispatch(
                  deleteDocument({
                    userId: user._id,
                    type: 'model',
                    id: _id,
                  }),
                );
                setConfirmOk(false);
              }}
              onOpenChange={() => console.log('open change')}
            >
              <Button
                size={'small'}
                style={{ justifySelf: 'flex-start', display: 'flex' }}
                icon={<DeleteOutlined style={{ marginTop: '5px' }} />}
              >
                Supprimer
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  const columnsCahier = [
    {
      title: 'Nom',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Modèle lié',
      dataIndex: 'template',
      key: 'template',
      render: (template) => template.name,
    },
    {
      title: 'Date de création',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (createdDate) => moment(createdDate).format('LLLL'),
    },
    {
      title: 'Action',
      dataIndex: '_id',
      width: '20%',
      render: (_id, record) => {
        // @ts-ignore
        // @ts-ignore
        // @ts-ignore
        return (
          <>
            <Space>
              <Button
                size={'small'}
                onClick={() =>
                  navigate(`/models/${record.template._id}/cahiers/${_id}`)
                }
              >
                <RightCircleOutlined />
                Détail et édition
              </Button>
              <Button
                size={'small'}
                onClick={() => {
                  dispatch(
                    duplicate({ userId: user._id, type: 'cahier', id: _id }),
                  );
                }}
              >
                <CopyOutlined />
                Dupliquer
              </Button>

              {user?.company ? (
                <Button
                  size={'small'}
                  disabled={loadingPdf} // @ts-ignore
                  onClick={() => getCahier(record.template._id, _id)}
                  value="small"
                >
                  <FilePdfOutlined />
                  PDF
                </Button>
              ) : (
                <Popover
                  content={"Vous n'êtes pas lié à une gérance"}
                  title={`Fonctionnalité non disponible`}
                  trigger="hover"
                >
                  <Button disabled={true} size={'small'}>
                    <FilePdfOutlined />
                    PDF
                  </Button>
                </Popover>
              )}

              <Popconfirm
                okText={'Supprimer'}
                title="Êtes-vous sûr de vouloir supprimer ce cahier ?"
                description={'Cette action est irréversible.'}
                onConfirm={() => {
                  dispatch(
                    deleteDocument({
                      userId: user._id,
                      type: 'cahier',
                      id: _id,
                    }),
                  );
                }}
                onOpenChange={() => console.log('open change')}
              >
                <Button
                  size={'small'}
                  style={{ justifySelf: 'flex-start', display: 'flex' }}
                  icon={<DeleteOutlined style={{ marginTop: '5px' }} />}
                >
                  Supprimer
                </Button>
              </Popconfirm>
            </Space>
          </>
        );
      },
    },
  ];

  const inputs = [
    {
      parentLabel: null,
      children: [
        {
          label: 'Rechercher (nom, prénom, email)',
          name: 'name',
          value: '',
          type: 'input',
          capitalize: false,
          col: 4,
        },
        {
          label: 'Status',
          name: 'status',
          value: 'all',
          type: 'select',
          selectContent: [
            {
              label: 'Tous',
              value: 'all',
            },
            ...statusOptionSimple,
          ],
          col: 4,
        },
      ],
    },
  ];

  const inputsNewModel = [
    {
      parentLabel: null,
      children: [
        {
          label: 'Nom',
          name: 'name',
          value: '',
          type: 'input',
          capitalize: false,
          required: true,
          col: 24,
        },
        {
          label: 'Modèles de base',
          name: 'template',
          allowClear: true,
          required: true,
          type: 'dynamic-select',
          endPoint: `/documents/templates_base`,
          itemCallback: (item) => {
            return {
              value: item._id,
              label: item.name,
            };
          },
          col: 24,
        },
      ],
    },
  ];
  const inputsNewCahier = [
    {
      parentLabel: null,
      children: [
        {
          label: 'Nom',
          name: 'name',
          value: '',
          type: 'input',
          capitalize: false,
          required: true,
          col: 24,
        },
        {
          label: 'Vos modèles',
          name: 'template',
          allowClear: true,
          required: true,
          type: 'dynamic-select',
          endPoint: `/documents/templates?user=${user._id}`,
          itemCallback: (item) => {
            return {
              value: item._id,
              label: item.name,
            };
          },
          col: 24,
        },
      ],
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    formCreateModel.current.submit();
    setIsModalOpen(false);
  };
  const handleOkCahier = () => {
    formCreatecahier.current.submit();
    setIsModalOpenCahier(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChangeData = (form, changedFields, allFields) => {
    setSearchName(form.getFieldValue('name'));
    setSearchStatus(form.getFieldValue('status'));
  };

  const submitNewModel = (data) => {
    // @ts-ignore
    dispatch(newTemplate({ userId: user._id, ...data }));
  };

  const submitNewCahier = (data) => {
    // @ts-ignore
    dispatch(newCahier({ userId: user._id, ...data }));
  };

  return (
    <>
      <Helmet>
        <title>Extranet USPI - Liste des cahiers</title>
        <script src="/pdfkit.standalone.js" type="text/javascript" />
        <script src="/blob-stream.js" type="text/javascript" />
        <meta name="description" content="Client détail" />
      </Helmet>
      <StickyWrapper>
        <Header
          style={{
            height: '80px',
            padding: 20,
            background: colorBgContainer,
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography.Title
            style={{
              margin: 15,
              color: ' rgb(0 21 41)',
              fontWeight: 300,
            }}
            className={''}
            level={1}
          >
            Cahier de prestations
          </Typography.Title>
        </Header>
      </StickyWrapper>
      <Content
        style={{
          margin: '24px 16px 0',
          overflow: 'initial',
          cursor: 'pointer',
        }}
      >
        <Card>
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <h2>Vos modèles</h2>

            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <Button
                onClick={showModal}
                style={{ justifySelf: 'flex-start', display: 'flex' }}
                icon={<FileAddOutlined style={{ marginTop: '5px' }} />}
              >
                Nouveau modèle
              </Button>
              <Modal
                title="Choississez le template pour votre modèle"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <CustomForm
                  dataSource={inputsNewModel}
                  labelCol={24}
                  wrapperCol={24}
                  resetOnFinish={true}
                  refForm={formCreateModel}
                  onValuesChange={undefined}
                  handleSubmit={submitNewModel}
                />
              </Modal>
            </div>
          </div>
          <br />

          <Table
            pagination={{
              hideOnSinglePage: true,
              position: ['bottomRight'],
              defaultPageSize: 10,
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
            }}
            loading={loadingTemplates}
            dataSource={templates}
            columns={columns}
            rowKey="_id"
          />
        </Card>

        <br />

        <Card>
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <h2>Vos cahiers</h2>

            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <Button
                onClick={() => setIsModalOpenCahier(true)}
                style={{ justifySelf: 'flex-start', display: 'flex' }}
                icon={<FileAddOutlined style={{ marginTop: '5px' }} />}
              >
                Nouveau cahier
              </Button>
              <Modal
                key={'cahier'}
                title="Choississez le model pour votre cahier"
                open={isModalOpenCahier}
                onOk={handleOkCahier}
                onCancel={() => setIsModalOpenCahier(false)}
              >
                <CustomForm
                  dataSource={inputsNewCahier}
                  labelCol={24}
                  wrapperCol={24}
                  resetOnFinish={true}
                  refForm={formCreatecahier}
                  onValuesChange={undefined}
                  handleSubmit={submitNewCahier}
                />
              </Modal>
            </div>
          </div>
          <br />
          <Table
            pagination={{
              hideOnSinglePage: true,
              position: ['bottomRight'],
              defaultPageSize: 10,
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
            }}
            loading={loadingCahiers || loadingPdf}
            dataSource={cahiers}
            columns={columnsCahier}
            rowKey="_id"
          />
        </Card>
      </Content>
      <div id={'pdf'} style={{ flex: 1 }}></div>
    </>
  );
}
