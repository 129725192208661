// @ts-nocheck

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { http } from '../../helpers/http';
import { notification } from 'antd';
import { getCompanies } from '../Companies/Companies.slice';

interface UsersState {
  users: object[];
  loading: boolean;
  companyLoading: boolean;
  company: {
    name: string;
    _id: string;
  };
}

const initialState: UsersState = {
  users: [],
  loading: false,
  companyLoading: false,
  company: null,
};

export const getUsers = createAsyncThunk(
  'posts/getUsers',
  async (params, thunkAPI) => {
    const res = await http.get('/users');
    return res.data;
  },
);


export const deleteUsers = createAsyncThunk(
  'delete/users',
  async (usersId, thunkAPI) => {
    const res = await http.post('/users/delete', { ids: usersId });
    thunkAPI.dispatch(getUsers());
    return res.data;
  },
);

export const getCompany = createAsyncThunk(
  'get/company',
  async (_id, thunkAPI) => {
    const res = await http.get(`/companies/${_id}`);
    return res.data;
  },
);
const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: {
    [getUsers.pending]: (state) => {
      state.loading = true;
    },
    [getUsers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.users = payload;
    },
    [getCompany.pending]: (state) => {
      state.companyLoading = true;
    },
    [getCompany.fulfilled]: (state, { payload }) => {
      state.companyLoading = false;
      state.company = payload;
    },
    [getUsers.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { increment, decrement, incrementByAmount, updateUserOnList } =
  usersSlice.actions;

export const usersReducer = usersSlice.reducer;
