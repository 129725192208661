// @ts-nocheck

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { notification } from 'antd';
import { http } from '../../helpers/http';

interface CompanyState {
  companyData: object;
  email: string;
  loading: boolean;
}

const initialState: CompanyState = {
  companyData: null,
  email: 'salut@default.com',
  loading: false,
};

export const getCompany = createAsyncThunk(
  'companys/getCompany',
  async (companyId: String, thunkAPI) => {
    const res = await http.get('/companies/' + companyId);
    return res.data;
  },
);

export const updateCompany = createAsyncThunk(
  'companys/update',
  async (company: CompanyState, thunkAPI) => {
    const res = await http.patch('/companies/' + company._id, company);
    return res.data;
  },
);

export const createCompany = createAsyncThunk(
  'companys/create',
  async (company: CompanyState, thunkAPI) => {
    const res = await http.post('/companies/', company);
    return res.data;
  },
);

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    clearCompany: (state) => {
      return initialState;
    },
  },
  extraReducers: {
    [getCompany.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [createCompany.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getCompany.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.companyData = payload;
    },
    [createCompany.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.companyData = payload;
    },
    [updateCompany.pending]: (state, { payload }) => {},
    [updateCompany.fulfilled]: (state, { payload }) => {
     // state.companyData = payload;
      notification.success({
        message: `Gérance modifiée`,
      });
    },
    [getCompany.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { clearCompany } = companySlice.actions;

export const companyReducer = companySlice.reducer;
