// @ts-nocheck

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { http } from '../../helpers/http';
import { notification } from 'antd';

interface InitState {
  statistics: {
    inactiveUserCount: number;
    cahiersExportedCount: number;
    latestDocuments: [];
    cahierCreatedThisMonth: number;
    totalGerance: number;
    totalCahiers: number;
    lastRegisteredUsers: [];
    totalUsers: number;
    uspiByCount: [{ value: string; count: number }];
    documentCount: number;
  };
  loading: boolean;
}

const initialState: InitState = {
  statistics: null,
  loading: false,
};

export const getStats = createAsyncThunk(
  'dash/getStats',
  async (unitId: String, thunkAPI) => {
    const res = await http.get('/documents/stats');
    return res.data;
  },
);

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    clearDashboard: (state) => {
      return initialState;
    },
  },
  extraReducers: {
    [getStats.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getStats.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.statistics = payload;
    },
  },
});

export const { clearDashboard } = dashboardSlice.actions;

export const dashboardReducer = dashboardSlice.reducer;
