import { useAppDispatch, useAppSelector } from '../../engine/hooks/redux.hooks';
import _ from 'lodash';
import {
  SyncOutlined,
  DeleteOutlined,
  FileAddOutlined,
} from '@ant-design/icons';

import {
  Button,
  Layout,
  Table,
  theme,
  Typography,
  Select,
  Popconfirm,
  Tag,
  Modal,
} from 'antd';
import { Helmet } from 'react-helmet';

import React, { useContext, useEffect, useState } from 'react';
import {
  colors,
  formatCurrency,
  statusOption,
  statusOptionSimple,
} from '../../helpers/constants';
import CustomForm from '../../components/CustomForm';
import {
  getCompany,
  getCompanies,
  CompanyModel,
  deleteCompanies,
} from './Companies.slice';
import { AppContext, ContextType } from '../../engine/contexts/App.context';
import {
  listCantons,
  listCantonsGerance,
  renderUspi,
  searchStringHelpers,
  StickyWrapper,
} from '../../helpers/utils';
import { NavigateFunction, useNavigate } from 'react-router-dom';
const { Content, Sider, Header, Footer } = Layout;

export function CompaniesList() {
  const navigate: NavigateFunction = useNavigate();

  const { user } = useContext<ContextType>(AppContext);

  const { companies, loading } = useAppSelector((state) => state.companies);
  const dispatch = useAppDispatch();
  const [selectedRowKeys, SetSelectedRowKeys] = useState([]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [searchName, setSearchName] = useState('');
  const [searchCity, setSearchCity] = useState(undefined);
  const [searchCanton, setSearchCanton] = useState(undefined);
  const [searchNpa, setSearchNpa] = useState('');
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const hideModalAndDelete = () => {
    setOpen(false);
    // @ts-ignore
    dispatch(deleteCompanies(selectedRowKeys));
  };

  useEffect(() => {
    // @ts-ignore
    dispatch(getCompanies());

    return () => {};
  }, []);

  const columns = [
    {
      title: 'Nom',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Canton',
      dataIndex: 'uspi',
      key: 'uspi',
      sorter: (a,b) => a.uspi.localeCompare(b.uspi),
      render: (uspi) =>
        listCantonsGerance.find((e) => e.value === uspi)?.label || uspi,
    },
    {
      title: 'Ville',
      dataIndex: 'address',
      key: 'address',
      render: (address) => address.city,
    },
    {
      title: 'NPA',
      dataIndex: 'address',
      key: 'address',
      render: (address) => address.npa,
      sorter: (a, b) => {
        if (a.address.npa < b.address.npa) {
          return -1;
        }
        if (a.address.npa > b.address.npa) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'Action',
      dataIndex: '_id',
      width: '15%',
      render: (_id, record) => {
        return (
          <Button onClick={() => navigate(`/companies/${_id}`)}>Editer</Button>
        );
      },
    },
  ];

  const generateFilters = () => {
    const cityFilter = [];

    companies.map((c) => {
      if (cityFilter.find((e) => e.value === c.address.city) === undefined) {
        cityFilter.push({
          label: c.address.city,
          value: c.address.city,
        });
      }
    });

    return cityFilter;
  };

  const generateFiltersCanton = () => {
    const cantonFilterUnique = [];

    companies.map((c) => {
      if (
        cantonFilterUnique.find((e) => e.value === c?.migration?.region) ===
        undefined
      ) {
        cantonFilterUnique.push({
          label: c?.migration?.region,
          value: c?.migration?.region,
        });
      }
    });

    return cantonFilterUnique;
  };

  const inputs = [
    {
      parentLabel: null,
      children: [
        {
          label: 'Rechercher par nom',
          name: 'name',
          value: '',
          type: 'input',
          capitalize: false,
          col: 5,
        },
        {
          label: 'Rechercher par Ville',
          name: 'city',
          type: 'select',
          showSearch: true,
          allowClear: true,
          selectContent: generateFilters(),
          col: 5,
        },
        {
          label: 'Rechercher par Canton',
          name: 'canton',
          type: 'select',
          showSearch: true,
          allowClear: true,
          selectContent: listCantonsGerance,
          col: 5,
        },
        {
          label: 'Rechercher par NPA',
          name: 'npa',
          value: '',
          type: 'input',
          capitalize: false,
          col: 5,
        },
        {
          type: 'reset',
          col: 4,
        },
      ],
    },
  ];

  const onChangeData = (form, changedFields, allFields) => {
    setSearchName(form.getFieldValue('name'));
    setSearchNpa(form.getFieldValue('npa'));
    setSearchCity(form.getFieldValue('city'));
    setSearchCanton(form.getFieldValue('canton'));
  };

  const manipulateArraySource = () => {
    const copy = _.cloneDeep(companies);

    let filtered = copy.filter((c) => {
      if (searchName !== '') {
        return searchStringHelpers(c.name, searchName);
      }

      if (searchNpa !== '') {
        return (
          c.address.npa
            ?.toString()
            ?.toLowerCase()
            ?.indexOf(searchNpa?.toLowerCase()) >= 0
        );
      }

      if (searchCity !== undefined) {
        return c.address.city === searchCity;
      }

      if (searchCanton !== undefined && searchCanton !== '') {
        return c?.uspi.toLowerCase() === searchCanton;
      }

      return true;
    });

    return filtered.sort((b, a) => {
      if (a.id > b.id) {
        return -1;
      }
      if (b.id > a.id) {
        return 1;
      }
      return 0;
    });
  };

  const rowSelection = {
    selectedRowKeys,
    selections: true,
    onChange: (r) => {
      SetSelectedRowKeys(r);
    },
  };

  return (
    <>
      <Helmet>
        <title>Extranet USPI - Liste des gérances</title>
        <meta name="description" content="Client détail" />
      </Helmet>
      <StickyWrapper>
        <Header
          style={{
            padding: 10,
            background: colorBgContainer,
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography.Title
            style={{
              marginBottom: 0,
              marginLeft: '20px',
              color: ' rgb(0 21 41)',
              fontWeight: 300,
              fontSize: '25px',
            }}
            className={''}
          >
            Gestion des gérances ({companies.length})
          </Typography.Title>
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              onClick={() => {
                navigate('/companies/new');
              }}
              type={'primary'}
              style={{
                justifySelf: 'flex-start',
                display: 'flex',
                marginRight: 20,
              }}
              icon={<FileAddOutlined style={{ marginTop: '5px' }} />}
            >
              Ajouter une gérance
            </Button>
            <Button
              onClick={showModal}
              style={{ justifySelf: 'flex-start', display: 'flex' }}
              disabled={selectedRowKeys.length === 0}
              icon={<DeleteOutlined style={{ marginTop: '5px' }} />}
            >
              Supprimer la sélection
            </Button>
            <Modal
              title="Attention !"
              open={open}
              onOk={hideModalAndDelete}
              onCancel={hideModal}
              okText="Je confirme"
              cancelText="J'annule"
            >
              <p>{`Vous allez supprimer ${selectedRowKeys.length} gérance${
                selectedRowKeys.length > 1 ? 's' : ''
              } !`}</p>
            </Modal>
          </div>
        </Header>
      </StickyWrapper>
      <Content
        style={{
          margin: '24px 16px 0',
          overflow: 'initial',
          cursor: 'pointer',
        }}
      >
        <div
          style={{
            padding: 24,
            background: colorBgContainer,
          }}
        >
          <CustomForm
            dataSource={inputs}
            labelCol={24}
            wrapperCol={24}
            onChangeData={onChangeData}
            handleSubmit={undefined}
            resetOnFinish={undefined}
            refForm={undefined}
            onValuesChange={undefined}
          />
          <Table
            rowSelection={rowSelection}
            pagination={{
              hideOnSinglePage: true,
              position: ['bottomRight'],
              defaultPageSize: 20,
              pageSizeOptions: [10, 20, 50, 100, 500, 1000],
              showSizeChanger: true,
            }}
            loading={loading}
            dataSource={manipulateArraySource()}
            columns={columns}
            rowKey="_id"
          />
        </div>
      </Content>
    </>
  );
}
