// @ts-nocheck

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { http } from '../../../helpers/http';
import { notification } from 'antd';
import { UnitType } from '../../../helpers/Unit.interface';

interface UnitsState {
  entities: UnitType[];
  loading: boolean;
  value: number;
}

const initialState: UnitsState = {
  entities: [],
  loading: false,
  value: 0,
};

export const getPosts = createAsyncThunk('posts/getPosts', async (thunkAPI) => {
  const res = await http.get('/units');
  return res.data;
});

export const createUnits = createAsyncThunk(
  'units/CreateUnits',
  async (units: [], thunkAPI) => {
    const res = await http.post('/units', { units });
    thunkAPI.dispatch(getPosts());
    return res.data;
  },
);

const unitsSlice = createSlice({
  name: 'units',
  initialState,
  reducers: {
    updateUnitOnList: (state, { payload }) => {
      state.entities = state.entities.map((item) => {
        if (item._id === payload._id) {
          return {
            ...item,
            ...payload,
          };
        } else {
          return item;
        }
      });
    },
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },
  },
  extraReducers: {
    [getPosts.pending]: (state) => {
      state.loading = true;
    },
    [getPosts.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.entities = payload;
    },
    [createUnits.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [createUnits.fulfilled]: (state, { payload }) => {
      notification.success({
        message: `Importés : ${payload.imported.length} `,
      });
      notification.warning({
        message: `Non importés : ${payload.notImported.length}`,
      });
      notification.error({
        message: `Supprimés : ${payload.deleted.length}`,
      });
    },
    [getPosts.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { increment, decrement, incrementByAmount, updateUnitOnList } =
  unitsSlice.actions;

export const unitsReducer = unitsSlice.reducer;
