'use client';
'use strict';
import React from 'react';

const ErrorBoundaryContext = React.createContext(null);

const initialState = {
  didCatch: false,
  error: null,
};
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.resetErrorBoundary = this.resetErrorBoundary.bind(this);
    this.state = initialState;
  }
  static getDerivedStateFromError(error) {
    return {
      didCatch: true,
      error,
    };
  }
  resetErrorBoundary(...args) {
    const { error } = this.state;
    if (error !== null) {
      this.props.onReset?.({
        args,
        reason: 'imperative-api',
      });
      this.setState(initialState);
    }
  }
  componentDidCatch(error, info) {
    this.props.onError?.(error, info);
  }
  componentDidUpdate(prevProps, prevState) {
    const { didCatch } = this.state;
    const { resetKeys } = this.props;

    // There's an edge case where if the thing that triggered the error happens to *also* be in the resetKeys array,
    // we'd end up resetting the error boundary immediately.
    // This would likely trigger a second error to be thrown.
    // So we make sure that we don't check the resetKeys on the first call of cDU after the error is set.

    if (
      didCatch &&
      prevState.error !== null &&
      hasArrayChanged(prevProps.resetKeys, resetKeys)
    ) {
      this.props.onReset?.({
        next: resetKeys,
        prev: prevProps.resetKeys,
        reason: 'keys',
      });
      this.setState(initialState);
    }
  }
  render() {
    const {
      children,
      fallbackRender,
      FallbackComponent,
      fallback,
    } = this.props;
    const { didCatch, error } = this.state;
    let childToRender = children;
    if (didCatch) {
      const props = {
        error,
        resetErrorBoundary: this.resetErrorBoundary,
      };
      if (React.isValidElement(fallback)) {
        childToRender = fallback;
      } else if (typeof fallbackRender === 'function') {
        childToRender = fallbackRender(props);
      } else if (FallbackComponent) {
        childToRender = React.createElement(FallbackComponent, props);
      } else {
        throw new Error(
          'React-error-boundary requires either a fallback, fallbackRender, or FallbackComponent prop',
        );
      }
    }
    return React.createElement(
      ErrorBoundaryContext.Provider,
      {
        value: {
          didCatch,
          error,
          resetErrorBoundary: this.resetErrorBoundary,
        },
      },
      childToRender,
    );
  }
}
function hasArrayChanged(a = [], b = []) {
  return (
    a.length !== b.length || a.some((item, index) => !Object.is(item, b[index]))
  );
}

function assertErrorBoundaryContext(value) {
  if (
    value == null ||
    typeof value.didCatch !== 'boolean' ||
    typeof value.resetErrorBoundary !== 'function'
  ) {
    throw new Error('ErrorBoundaryContext not found');
  }
  return true;
}

function useErrorBoundary() {
  const context = React.useContext(ErrorBoundaryContext);
  assertErrorBoundaryContext(context);
  const [state, setState] = React.useState({
    error: null,
    hasError: false,
  });
  const memoized = React.useMemo(
    () => ({
      resetBoundary: () => {
        context?.resetErrorBoundary();
        setState({
          error: null,
          hasError: false,
        });
      },
      showBoundary: error =>
        setState({
          error,
          hasError: true,
        }),
    }),
    [context?.resetErrorBoundary],
  );
  if (state.hasError) {
    throw state.error;
  }
  return memoized;
}

function withErrorBoundary(component, errorBoundaryProps) {
  const Wrapped = React.forwardRef((props, ref) =>
    React.createElement(
      ErrorBoundary,
      errorBoundaryProps,
      React.createElement(component, {
        ...props,
        ref,
      }),
    ),
  );

  // Format for display in DevTools
  const name = component.displayName || component.name || 'Unknown';
  Wrapped.displayName = `withErrorBoundary(${name})`;
  return Wrapped;
}

export {
  ErrorBoundary,
  ErrorBoundaryContext,
  useErrorBoundary,
  withErrorBoundary,
};
