// @ts-nocheck

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { notification } from 'antd';
import { http } from '../../helpers/http';

interface UserState {
  userData: object;
  email: string;
  loading: boolean;
  updateSuccess: boolean;
  createSuccess: boolean;
}

const initialState: UserState = {
  userData: null,
  email: 'salut@default.com',
  loading: false,
  updateSuccess: false,
  createSuccess: false,
};

export const getUser = createAsyncThunk(
  'users/getUser',
  async (userId: String, thunkAPI) => {
    const res = await http.get('/users/' + userId);
    return res.data;
  },
);

export const createUser = createAsyncThunk(
  'users/createUser',
  async (props, { rejectWithValue }) => {
    try {
      const res = await http.post('/users/', props.data);


      if (props.email) {
        const invitation = await http.post('/users/invitation', {
          id: res.data._id,
        });

        return res.data;
      } else {
        return res.data;
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const updateUser = createAsyncThunk(
  'users/update',
  async (user: UserState, thunkAPI) => {
    const res = await http.patch('/users/' + user._id, user);
    return res.data;
  },
);

export const sendInvitation = createAsyncThunk(
  'users/sendInvitation',
  async (id, thunkAPI) => {
    const res = await http.post('/users/invitation', { id });
    notification.open({
      type: 'success',
      key: 'info',
      message: `L'email d'invitation a été envoyé.`,
    });
    return res.data;
  },
);

export const updatePassword = createAsyncThunk(
  'users/reset_admim',
  async ({ _id, password }, thunkAPI) => {
    const res = await http.post('/users/reset_admim', { _id, password });
    return res.data;
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUser: (state) => {
      return initialState;
    },
  },
  extraReducers: {
    [getUser.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userData = payload;
    },
    [updatePassword.fulfilled]: (state, { payload }) => {
      notification.open({
        type: 'success',
        key: 'update',
        message: `Mot de passe modifié`,
      });
    },
    [updateUser.pending]: (state, { payload }) => {
      state.updateSuccess = false;
    },
    [createUser.pending]: (state, { payload }) => {
      state.loading = true;
      state.updateSuccess = false;
    },
    [createUser.rejected]: (state, result) => {
      state.loading = false;
      notification.open({
        type: 'error',
        key: 'error',
        message: `Une erreur est survenue `,
        description: `${result.payload.message}`,
      });
    },
    [createUser.fulfilled]: (state, { payload }) => {
      state.loading = false;

      state.userData = payload;
      state.createSuccess = true;

      notification.open({
        type: 'success',
        key: 'update',
        message: `Compte créé `,
        description: `Pour ${payload.firstname} ${payload.lastname}`,
      });
      notification.open({
        type: 'success',
        key: 'info',
        message: `L'email d'invitation a été envoyé.`,
      });
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      state.userData = payload;
      state.updateSuccess = true;

      notification.open({
        type: 'success',
        key: 'update',
        message: `Compte modifié `,
        description: `Pour ${payload.firstname} ${payload.lastname}`,
      });
    },
    [getUser.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { clearUser } = userSlice.actions;

export const userReducer = userSlice.reducer;
