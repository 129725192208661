// @ts-nocheck

/**
 *
 * CustomForm
 *
 */

import React, { memo, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Slider,
  Switch,
} from 'antd';
import ColFormItemWrapper from './ColWFormItemWrapper';
import SelectFieldAxios from '../SelectFieldAxios';
import './style.css';
import SelectAddItem from './SelectAddItem';

import moment from 'moment';
import 'moment/locale/fr';
import AddressForm from './AddressForm';
import { currencyFormatter } from './form.utils';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../../helpers/constants';

const { RangePicker } = DatePicker;

const { Panel } = Collapse;
const { TextArea } = Input;
const { Option } = Select;

const renderlabel = (option) => {
  if (option.label === undefined) {
    return option?.value;
  }

  if (typeof option.label === 'string' || option.label instanceof String) {
    return option.label;
  }

  if (option.label instanceof Function) {
    return option.label();
  }
};

export const renderInputType = (
  item,
  index,
  form,
  refForm,
  disabled = false,
) => {
  switch (item.type) {
    case 'input-currency':
      return (
        <ColFormItemWrapper item={item} index={index}>
          <InputNumber
            controls={false}
            formatter={currencyFormatter}
            onChange={item.onChange !== undefined ? item.onChange : () => {}}
            onBlur={(e) => {
              return currencyFormatter(e.target.value);
            }}
            placeholder={item.label}
            style={{ width: '100%' }}
            disabled={item.disabled || disabled}
            {...item}
          />
        </ColFormItemWrapper>
      );
    case 'input':
      return (
        <ColFormItemWrapper item={item} index={index}>
          <Input
            onChange={item.onChange !== undefined ? item.onChange : () => {}}
            defaultValue={''}
            disabled={item.disabled || disabled}
            placeholder={
              item.placeholder !== undefined ? item.placeholder : item.label
            }
            {...item}
          />
        </ColFormItemWrapper>
      );
    case 'password':
      return (
        <ColFormItemWrapper item={item} index={index}>
          <Input.Password
            onChange={item.onChange !== undefined ? item.onChange : () => {}}
            defaultValue={''}
            disabled={item.disabled || disabled}
            placeholder={
              item.placeholder !== undefined ? item.placeholder : item.label
            }
          />
        </ColFormItemWrapper>
      );
    case 'slider':
      return (
        <ColFormItemWrapper item={item} index={index}>
          <Slider
            max={item.max}
            tooltipVisible={item.tooltipVisible}
            min={item.min}
            range={item.range}
            disabled={item.disabled || disabled}
            placeholder={
              item.placeholder !== undefined ? item.placeholder : item.label
            }
          />
        </ColFormItemWrapper>
      );
    case 'textarea':
      return (
        <ColFormItemWrapper item={item} index={index}>
          <TextArea
            placeholder={item.label}
            rows={4}
            disabled={item.disabled || disabled}
          />
        </ColFormItemWrapper>
      );
    case 'breakline':
      return <div style={{ width: '100%' }} />;
    case 'select-add':
      return <SelectAddItem index={index} item={item} />;
    case 'switch':
      return (
        <ColFormItemWrapper item={item} index={index}>
          <Switch
            checkedChildren={item.yes || 'oui'}
            unCheckedChildren={item.no || 'non'}
            defaultChecked={item.value}
          >
            {item.description && <p>{item.description}</p>}
          </Switch>
        </ColFormItemWrapper>
      );
    case 'range-picker':
      return (
        <ColFormItemWrapper item={item} index={index}>
          <RangePicker
            className={'custom-range-picker'}
            format={'DD/MM/YYYY'}
          />
        </ColFormItemWrapper>
      );
    case 'select':
      return (
        <ColFormItemWrapper item={item} index={index}>
          <Select
            onClear={item.onClear}
            allowClear={item.allowClear ? true : false}
            showSearch={item.showSearch ? true : false}
            disabled={item.disabled || disabled}
            mode={item.multiple ? 'multiple' : 'tag'}
            optionFilterProp="children"
            onSelect={(LabeledValue, option) => {
              // console.log({ LabeledValue, option });
            }}
            className={item.select === 'colorfull' ? 'colorfull' : ''}
            placeholder={
              item.placeholder !== undefined ? item.placeholder : item.label
            }
          >
            {item.selectContent.map((option, i) => (
              <Option
                disabled={option.disabled}
                key={option?.key || option?.value}
                value={option?.value || option?.key}
                color={option?.color}
              >
                {renderlabel(option)}
              </Option>
            ))}
          </Select>
        </ColFormItemWrapper>
      );
    case 'number':
      return (
        <ColFormItemWrapper item={item} index={index}>
          <InputNumber
            controls={false}
            placeholder={item.label}
            style={{ width: '100%' }}
            disabled={item.disabled || disabled}
            {...item}
          />
        </ColFormItemWrapper>
      );
    case 'date':
      const { picker = 'date' } = item;
      return (
        <ColFormItemWrapper item={item} index={index}>
          <DatePicker
            onChange={(d, v) => {}}
            defaultPickerValue={() => {
              if (item.default !== undefined) {
                if (item.default instanceof moment) {
                  return item.default;
                } else {
                  return moment(item.default);
                }
              }
              return moment();
            }}
            allowClear={item.allowClear !== undefined ? item.allowClear : false}
            value={item.value}
            picker={picker}
            style={{ width: '100%' }}
            disabled={item.disabled || disabled}
            format={'DD.MM.YYYY'}
          />
        </ColFormItemWrapper>
      );
    case 'component':
      return (
        <ColFormItemWrapper item={item} index={index}>
          {item.component()}
        </ColFormItemWrapper>
      );
    case 'dynamic-select':
      return (
        <SelectFieldAxios
          refForm={refForm}
          updateEndPoint={item.updateEndPoint}
          ready={item.ready}
          itemsCallBack={(e) => {
            if (item.itemsCallBack !== undefined) {
              item.itemsCallBack(e, form);
            }
          }}
          endPoint={item.endPoint}
          itemCallback={item.itemCallback}
          filterItem={item.filterItem}
          item={item}
        />
      );

    case 'children':
      return item.render();
    case 'submit':
      return (
        !disabled && (
          <ColFormItemWrapper item={item} index={index}>
            <Button
              type="primary"
              htmlType="submit"
              size={'small'}
              loading={item.loading === undefined ? false : item.loading}
            >
              {item.label}
            </Button>
          </ColFormItemWrapper>
        )
      );
    case 'reset':
      return (
        !disabled && (
          <ColFormItemWrapper item={item} index={index}>
            <Button
              style={{ marginTop: '40px' }}
              type="ghost"
              htmlType="reset"
              size={'large'}
              loading={item.loading === undefined ? false : item.loading}
            >
              Remettre à zéro
            </Button>
          </ColFormItemWrapper>
        )
      );
    case 'addressForm':
      return (
        <AddressForm
          disabled={disabled}
          form={form}
          item={item}
          index={index}
          refForm={refForm}
          autoCompleteSearch={item.autoCompleteSearch}
          withLocator={item.withLocator}
        />
      );
    case 'email':
      return (
        <ColFormItemWrapper item={item} index={index}>
          <Input
            type={'email'}
            onChange={item.onChange !== undefined ? item.onChange : () => {}}
            defaultValue={''}
            disabled={item.disabled || disabled}
            placeholder={
              item.placeholder !== undefined ? item.placeholder : item.label
            }
          />
        </ColFormItemWrapper>
      );
  }
};

function CustomForm({
  dataSource,
  disabled = false,
  labelCol,
  wrapperCol,
  handleSubmit,
  onChangeData,
  resetOnFinish,
  refForm,
  onValuesChange,
  initialValues = null,
}) {
  const [form] = Form.useForm();
  const [activekey, setActivekey] = useState([]);

  const dispatch = useDispatch();

  const preventChangingRoute = () => {
    return true;
  };

  useEffect(() => {
    // console.log('didmount');

    if (initialValues !== null) {
      form.setFieldsValue({
        ...initialValues,
      });
    }
  }, []);

  useEffect(() => {
    const handleTabClose = (event) => {
      return true;

      // This dosent work
      event.preventDefault();

      return (event.returnValue = '');
    };

    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  const generateSubObject = (values) => {
    let copyValues = { ...values };

    // remove Empty value of select
    dataSource.map((section) => {
      section.children.map((child) => {
        // Parse Date first
        if (child.hidden === undefined || child.hidden === false) {
          if (child.type === 'date' || child.type === 'date-accounting') {
            if (copyValues[child.name] !== null) {
              copyValues[child.name] = copyValues[child.name].format();
            }
          }
        }

        if (child.type === 'select' || child.type === 'dynamic-select') {
          if (
            copyValues[child.name] !== undefined &&
            copyValues[child.name] !== null
          ) {
            if (
              copyValues[child.name] === '' ||
              copyValues[child.name].length === 0
            ) {
              delete copyValues[child.name];
            }
          }
        }
      });
    });

    Object.keys(copyValues).map((key) => {
      // Split by .
      const nameSplit = key.split('.');

      // create initial sub object
      if (copyValues[nameSplit[0]] === undefined) {
        copyValues[nameSplit[0]] = {};
      }

      // Sub object !
      // level 2
      if (nameSplit.length === 2) {
        // Mutate object
        copyValues[nameSplit[0]] = {
          ...copyValues[nameSplit[0]],
          [nameSplit[1]]: copyValues[key],
        };
        // Delete old ref
        delete copyValues[key];
      }
      // Level 3
      if (nameSplit.length === 3) {
        // Create initial sub sub object
        if (copyValues[nameSplit[0]][nameSplit[1]] === undefined) {
          copyValues[nameSplit[0]] = {
            ...copyValues[nameSplit[0]],
            [nameSplit[1]]: {},
          };
        }

        // Mutate object
        copyValues[nameSplit[0]] = {
          ...copyValues[nameSplit[0]],
          [nameSplit[1]]: {
            ...copyValues[nameSplit[0]][nameSplit[1]],
            [nameSplit[2]]: copyValues[key],
          },
        };

        // Delete old ref
        delete copyValues[key];
      }
    });

    Object.keys(copyValues).map((i) => {
      if (
        typeof copyValues[i] === 'object' &&
        copyValues[i] !== null &&
        Object.keys(copyValues[i]).length === 0
      ) {
        copyValues[i] = null;
      }
    });

    // remove Empty
    return copyValues;
  };

  const onFinish = (values) => {
    if (!disabled) {
      handleSubmit(generateSubObject(values), form);

      if (resetOnFinish) {
        form.resetFields();
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (!disabled) {
      notification.error({
        message: errorInfo.errorFields.length + ' champs sont nécessaires',
      });

      let recreateit = [];

      dataSource.map((e) => {
        let child = { ...e, children: [] };

        e.children.map((o) => {
          if (o.componentLabel === undefined) {
            child.children.push(o);
          }
        });
        recreateit.push(child);
        return e;
      });

      // We dont take componentLabel because react element and stringify make it lose
      let copy = JSON.parse(JSON.stringify(recreateit));

      let keys = copy.filter((e) => e.collapse).map((e) => e.parentLabel);
      setActivekey(keys);

      console.log('Failed:', errorInfo);
    }
  };

  const onChangeActive = (a) => {
    setActivekey(a);
  };

  const onFieldsChange = (changedFields, allFields) => {
    onChangeData(form, changedFields, allFields);
  };

  const renderChild = (item) => {
    return item.children.map((c, i) => {
      if (c?.hidden) {
        return null;
      } else {
        return renderInputType(c, i, form, refForm, disabled);
      }
    });
  };

  return (
    <div>
      <Form
        initialValues
        ref={refForm}
        onFieldsChange={onFieldsChange}
        onValuesChange={onValuesChange}
        form={form}
        name="basic"
        labelCol={{ span: labelCol || 8 }}
        wrapperCol={{ span: wrapperCol || 16 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onReset={onFieldsChange}
        autoComplete="off"
        className={'custom-form'}
      >
        {dataSource.map((item, i) => {
          let Wrapper = null;

          if (item?.type === 'card') {
            Wrapper = Card;
          } else {
            Wrapper = React.Fragment;
          }

          return (
            <Wrapper
              style={{
                width: '100%',
                marginBottom: 20,
              }}
            >
              <Row key={item.key} gutter={[15, 15]}>
                {item?.parentLabel !== null && (
                  <Col span={24}>
                    <h3
                      className="ant-form-item-title"
                      // style={{ color: colors.blue }}
                    >
                      {item.parentLabel}
                    </h3>
                  </Col>
                )}

                {item.collapse ? (
                  <Collapse
                    defaultActiveKey={[item.defaultActiveKey]}
                    style={{ width: '100%' }}
                    ghost
                    onChange={onChangeActive}
                  >
                    <Panel
                      className={'collaps-header-form'}
                      header={'Modifier les données par défaut'}
                      key={item.parentLabel}
                      style={{ width: '100%' }}
                      forceRender={true}
                    >
                      <Row key={item.key + 'collapse'}>{renderChild(item)}</Row>
                    </Panel>
                  </Collapse>
                ) : (
                  renderChild(item)
                )}
              </Row>
            </Wrapper>
          );
        })}
      </Form>
    </div>
  );
}

CustomForm.propTypes = {};

CustomForm.defaultProps = {
  onChangeData: () => {},
};

export default memo(CustomForm);
