// @ts-nocheck

import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import React, { useContext } from 'react';
import { Breadcrumb, Button, Layout } from 'antd';
import { UnitsList } from '../../pages/Units/UnitsList/Units.list';
import { Login } from '../../pages/auth/login/Login';
import { AppContext } from '../contexts/App.context';
import { Menu, theme } from 'antd';
import {
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { SiderNavigation } from '../../layouts/Sider.navigation';
import PublicRegister from '../../pages/auth/PublicRegister/PublicRegister';
import { Dashboard } from '../../pages/dashboard/Dashboard';
import { DocumentsList } from '../../pages/Documents/Documents.list';
import { UserDetail } from '../../pages/User/User.detail';
import { UsersList } from '../../pages/Users/Users.list';
import { CahiersList } from '../../pages/Cahiers/Cahiers.list';
import { CompaniesList } from '../../pages/Companies/Companies.list';
import { CompanyDetail } from '../../pages/Company/Company.detail';
import { CahierModelDetail } from '../../pages/CahierModel/CahierModel.detail';
import { Help } from '../../pages/Help/Help';
import { PasswordLost } from '../../pages/auth/PasswordLost/PasswordLost';
import { PasswordReset } from '../../pages/auth/PasswordReset/PasswordReset';
import { NoMatch404 } from '../../pages/NoMatch404/NoMatch404';

const { Content, Sider, Header, Footer } = Layout;
export default function Routing() {
  const { isConnected } = useContext(AppContext);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <AuthRoute redirectTo="/login">
              <Layout hasSider>
                <SiderNavigation></SiderNavigation>
                <Layout
                  className="site-layout"
                  style={{ marginLeft: 220, minHeight: '100vh' }}
                >
                  <div style={{ flex: 1, overflow: 'scroll' }}>
                    <Outlet />
                  </div>
                  <Footer
                    style={{
                      textAlign: 'center',
                      display: 'inline-block',
                    }}
                  >
                    Copyright Niseko ©{new Date().getFullYear()}
                  </Footer>
                </Layout>
              </Layout>
            </AuthRoute>
          }
        >
          <Route index element={<Dashboard />} />
          <Route
            path={'documents/meeting'}
            element={
              <DocumentsList
                folder={'meeting'}
                key={'documentsmeeting'}
                title={'Documents assemblée'}
              />
            }
          />
          <Route
            path={'documents/utils'}
            element={
              <DocumentsList
                folder={'utils'}
                key={'documentsutils'}
                title={'Documents utiles'}
              />
            }
          />
          <Route
            path={'documents/uspi'}
            element={
              <DocumentsList
                folder={'uspi'}
                key={'documentsuspi'}
                title={'USPI Infos'}
              />
            }
          />
          <Route
            path={'documents/fribourg'}
            element={
              <DocumentsList
                folder={'fribourg'}
                key={'documentsfribourg'}
                title={'Documents Fribourg'}
              />
            }
          />
          <Route path={'cahiers'} element={<CahiersList />} />
          <Route
            path={'models/:id/cahiers/:cahier'}
            element={<CahierModelDetail isModel={false} />}
          />
          <Route
            path={'models/:id'}
            element={<CahierModelDetail isModel={true} />}
          />

          <Route path={'user'} element={<UserDetail key={'myaccount'} />} />
          <Route
            path={'user/:id'}
            element={<UserDetail key={'UserDetail'} isNew={false} />}
          />
          <Route
            path={'user/new'}
            element={<UserDetail key={'UserDetail'} isNew={true} />}
          />
          <Route
            path={'companies/:id'}
            element={<CompanyDetail key={'CompanyDetail'} isNew={false} />}
          />
          <Route
            path={'companies/new'}
            element={<CompanyDetail key={'CompanyDetail'} isNew={true} />}
          />
          <Route path={'users'} element={<UsersList />} />
          <Route path={'help'} element={<Help />} />
          <Route
            path={'companies'}
            element={<CompaniesList />}
            loader={async ({ request, params }) => {
              return fetch(`/companies`, {
                signal: request.signal,
              });
            }}
          />
          <Route path={'units'} element={<UnitsList />} />
        </Route>

        <Route path="auth" element={<Outlet />}>
          <Route index element={<Login />} />
          <Route path="register" element={<PublicRegister />} />
          <Route path="lost" element={<PasswordLost />} />
          <Route path="reset" element={<PasswordReset />} />
        </Route>

        <Route path="*" element={<NoMatch404 />}></Route>
      </Routes>
    </BrowserRouter>
  );
}
