// @ts-nocheck

import {
  Button,
  Card,
  Col,
  Layout,
  Modal,
  notification,
  Row,
  Skeleton,
  Space,
  Table,
  Tag,
  theme,
  Typography,
  Upload,
} from 'antd';
import Compressor from 'compressorjs';

import { Helmet } from 'react-helmet';

import React, {
  useContext,
  useEffect,
  companyef,
  useState,
  useRef,
} from 'react';
import { useAppDispatch, useAppSelector } from '../../engine/hooks/redux.hooks';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  clearCompany,
  getCompany,
  updateCompany,
  createCompany,
} from './Company.slice';
import {
  AlertOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  LeftOutlined,
  SaveFilled,
} from '@ant-design/icons';
import moment from 'moment/moment';
import Title from 'antd/es/typography/Title';
import CustomForm from '../../components/CustomForm';
import { AppContext, ContextType } from '../../engine/contexts/App.context';
import { listCantons, renderUspi } from '../../helpers/utils';
import dayjs from 'dayjs';
import {
  apiUrl,
  daysJsFormat,
  localStorageUserKey,
} from '../../helpers/constants';
import { isMap } from 'util/types';
// import ImgCrop from 'antd-img-crop';
import ImgCrop from './Cropper/antd-img-crop.esm';
const { Content, Sider, Header, Footer } = Layout;

export function CompanyDetail({ isNew }) {
  const { companyData, loading } = useAppSelector((state) => state.company);
  const navigate = useNavigate();

  const {
    name,
    // migration: { region = '' } = {},
    email = '',
    uspi = '',
    phone = '',
    address: {
      longitude = '',
      latitude = '',
      street = '',
      zip = '',
      npa = '',
      number = '',
      canton = '',
      city = '',
      country = '',
      beneficiary = '',
      mobile = '',
    } = {},
  } = companyData || {};

  const dispatch = useAppDispatch();

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [loadingUpload, setLoadingUpload] = useState(false);

  const formRef = useRef(null);

  const { id: companyId } = useParams();

  useEffect(() => {
    if (!isNew) {
      dispatch(getCompany(companyId));
    }
    return () => {
      dispatch(clearCompany());
    };
  }, []);

  useEffect(() => {
    if (isNew && companyData?._id !== undefined) {
      // navigate(`/expense/${expenseDetailData._id}`, { replace: true });
      navigate(`/companies/${companyData._id}`, { replace: true });
    }
  }, [companyData]);

  const [loadingSvg, setLoadingSvg] = useState(false);

  const onSubmitForm = (data) => {
    if (isNew) {
      dispatch(createCompany(data));
    } else {
      data._id = companyData._id;
      dispatch(updateCompany(data));
    }
  };

  const inputs = [
    {
      parentLabel: 'Informations',
      children: [
        {
          label: 'Nom',
          name: 'name',
          value: name,
          required: true,
          capitalize: false,
          type: 'input',
          col: 12,
        },
        {
          label: 'Canton associé',
          name: 'uspi',
          value: uspi.toLowerCase(),
          required: true,
          type: 'select',
          selectContent: listCantons,
          col: 12,
        },
        {
          label: 'Email',
          name: 'email',
          value: email,
          type: 'email',
          col: 12,
        },
      ],
    },
    {
      parentLabel: 'Adresse',
      children: [
        {
          label: 'Rue',
          name: 'address.street',
          value: street,
          type: 'input',
          col: 11,
        },
        // {
        //   label: 'Numéro',
        //   name: 'address.number',
        //   value: number,
        //   type: 'input',
        //   col: 6,
        // },
        {
          label: 'NPA',
          name: 'address.npa',
          value: npa,
          type: 'input',
          col: 4,
        },
        {
          label: 'Ville',
          name: 'address.city',
          value: city,
          type: 'input',
          col: 9,
        },
      ],
    },
  ];

  const onValuesChange = () => {};
  const accept = ['image/bmp', 'image/gif', 'image/jpeg', 'image/png'];
  const token = localStorage.getItem(localStorageUserKey);

  const propsDrag = {
    name: 'file',
    multiple: true,
    action: apiUrl() + `/companies/${companyId}/images`,
    data: { hello: 'true' },
    accept,
    type: 'POST',
    showUploadList: false,
    headers: { Authorization: 'Token ' + token },
    beforeUpload: (file, files) => {
      if (file.size / 1024 / 1024 > 10) {
        return false;
      } else {
        return true;
      }
    },
    beforeUpload: (file, files) => {
      // Compress the image using Compressor.js
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.6, // Adjust the quality value as per your needs
          maxWidth: 600, // Set the maximum width of the compressed image
          maxHeight: 600, // Set the maximum height of the compressed image
          success(result) {
            // Replace the original file with the compressed file
            resolve(result);
          },
          error(error) {
            reject(error);
          },
        });
      });
    },
    onChange: (e) => {
      if (e.file.status === 'uploading') {
        setLoadingUpload(true);
      }

      if (e.file.status === 'done') {
        // Hacking because the callback is too fast
        setTimeout(() => {
          setLoadingUpload(false);
          notification.success({ message: "Upload de l'image réussie" });
          dispatch(getCompany(companyId));
        }, 1000);
      }
    },
  };

  return (
    <div>
      <Helmet>
        <title>Extranet USPI - Détail gérance</title>
      </Helmet>

      <Header
        style={{
          padding: 10,
          background: colorBgContainer,
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography.Title
          style={{
            marginBottom: 0,
            marginLeft: '20px',
            color: ' rgb(0 21 41)',
            fontWeight: 300,
            fontSize: '25px',
          }}
          className={''}
        >
          {isNew ? 'Ajouter une gérance' : `Détail gérance : ${name}`}
        </Typography.Title>
        <Row gutter={[15, 15]}>
          <Col span={24}>
            <Space>
              <Button
                type="primary"
                icon={<SaveFilled />}
                onClick={() => {
                  formRef.current.submit();
                }}
              >
                Sauvegarder
              </Button>
            </Space>
          </Col>
        </Row>
      </Header>
      <Content
        style={{
          margin: '20px',
          overflow: 'initial',
        }}
      >
        {companyData === null && !isNew ? (
          <Skeleton />
        ) : (
          <Row gutter={[15, 15]}>
            <Col span={24}>
              <Card>
                <CustomForm
                  handleSubmit={onSubmitForm}
                  dataSource={inputs}
                  refForm={formRef}
                  onValuesChange={onValuesChange}
                  labelCol={24}
                  wrapperCol={24}
                />
              </Card>
              <br />
              {!isNew && (
                <Card loading={loading}>
                  <h3>Logo</h3>
                  <ImgCrop
                    disabled={isNew}
                    rotationSlider
                    showReset
                    fillColor={'white'}
                    aspect={1.4}
                    aspectSlider={true}
                    minZoom={1}
                    maxZoom={2}
                    grid={true}
                    cropperProps={{
                      // cropSize: { width: 100, height: 200 },
                      crop: { x: 0, y: 0 },
                      aspect: 8 / 3,
                      // aspect: 40 / 100,
                      objectFit: 'horizontal-cover',
                    }}
                    rotate={true}
                    resetText={'Réinitialiser'}
                    modalTitle={'Adaptez votre logo'}
                  >
                    <Upload {...propsDrag}>
                      <Button
                        disabled={isNew}
                        loading={loadingUpload}
                        key="pdfbutton"
                        type="primary"
                        size="large"
                      >
                        {companyData?.logo
                          ? 'Télécharger votre logo'
                          : 'Changer le logo'}
                      </Button>
                    </Upload>
                  </ImgCrop>
                  <br />
                  <br />
                  {companyData?.logo && (
                    <img
                      style={{ width: 400 }}
                      src={`data:image/png;base64, ${companyData.logo}`}
                      alt="Image"
                    />
                  )}
                </Card>
              )}

              <br />
            </Col>
          </Row>
        )}
      </Content>
    </div>
  );
}
