// @ts-nocheck

import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Form, Input, Button, Checkbox, Space, Card, notification } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './Login.css';
import Background_login from '../../../images/realestate-back.jpeg';
import Logo from '../../../assets/logo_uspi_suisse.svg';
import { AppContext } from '../../../engine/contexts/App.context';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CustomForm from '../../../components/CustomForm';
import { http } from '../../../helpers/http';
import { updatePassword } from '../../User/User.slice';

export const PasswordReset = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const token = searchParams.get('token');

  useEffect(() => {
    if (token === null) {
      navigate('/', { replace: true });
    }
  }, []);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (data) => {
    setLoading(true);

    let send = await http.post('/auth/lost', data);

    notification.success({
      message: `Demande de mot de passe`,
      description:
        'Si votre adresse e-mail figure dans notre système, vous recevrez un e-mail contenant les instructions.',
    });
    setLoading(false);
  };

  const inputsPassword = [
    {
      parentLabel: 'Changer le Mot de passe',
      children: [
        {
          label: 'Nouveau mot de passe',
          name: 'password1',
          value: '',
          type: 'password',
          col: 11,
          offset: 0,
          required: true,
        },
        {
          label: 'Recopier le nouveau mot de passe',
          name: 'password2',
          value: '',
          required: true,
          type: 'password',
          col: 12,
          offset: 1,
        },
      ],
    },
    {
      children: [
        {
          label: 'Changer le mot de passe',
          name: 'send',
          value: 'send',
          type: 'submit',
          col: 24,
          align: 'left',
        },
      ],
    },
  ];
  const onSubmitFormPassword = async (data) => {
    setLoading(true);

    if (data.password1 !== data.password2) {
      notification.error({ message: 'Mot de passe pas identique' });
    } else if (data.password1.length < 3) {
      notification.error({ message: 'Mot de passe trop court' });
    } else {
      // dispatch(updatePassword({ _id: userData._id, password: data.password2 }));

      let send = await http.post('/auth/reset', {
        password: data.password1,
        token,
      });


      notification.success({
        message: `Vous pouvez vous connecter`,
        description: 'Votre mot de passe a été changé.',
      });
      navigate('/', { replace: true})
    }
    setLoading(false);
  };

  return (
    <div
      className={'login-back'}
      style={{ backgroundImage: `url(${Background_login})` }}
    >
      <Helmet>
        <title>Extranet USPI - Nouveau mot de passe </title>
      </Helmet>
      <div className={'login-container'} style={{ width: '700px' }}>
        <div className={'login-box'}>
          <img src={Logo} className={'login-logo'} />
          <CustomForm
            dataSource={inputsPassword}
            labelCol={24}
            wrapperCol={24}
            handleSubmit={onSubmitFormPassword}
          />
        </div>
      </div>
    </div>
  );
};
