// @ts-nocheck

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { http } from '../../helpers/http';
import { notification } from 'antd';
import { getPosts } from '../Units/UnitsList/Units.slice';

interface AddressModel {
  longitude: string;
  latitude: string;
  street: string;
  npa: string;
  number: string;
  canton: string;
  city: string;
  country: string;
  beneficiary?: string;
}

interface MigrationModel {
  id: string;
  logo: string;
  folder: string;
  region: string;
  active: boolean;
}

export interface CompanyModel {
  address: AddressModel;
  migration: MigrationModel;
  name: string;
  status: string;
}
interface CompaniesState {
  companies: CompanyModel[];
  loading: boolean;
}

const initialState: CompaniesState = {
  companies: [],
  loading: false,
  companyLoading: false,
  company: null,
};

export const getCompanies = createAsyncThunk(
  'posts/getCompanies',
  async (params, thunkAPI) => {
    const res = await http.get('/companies');
    return res.data;
  },
);

export const deleteCompanies = createAsyncThunk(
  'delete/companies',
  async (companiesId, thunkAPI) => {
    const res = await http.post('/companies/delete', { ids: companiesId });
    thunkAPI.dispatch(getCompanies());
    return res.data;
  },
);

export const getCompany = createAsyncThunk(
  'get/company',
  async (_id, thunkAPI) => {
    const res = await http.get(`/companies/${_id}`);
    return res.data;
  },
);

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {},
  extraReducers: {
    [getCompanies.pending]: (state) => {
      state.loading = true;
    },
    [getCompanies.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.companies = payload;
    },
    [getCompany.pending]: (state) => {
      state.companyLoading = true;
    },
    [getCompany.fulfilled]: (state, { payload }) => {
      state.companyLoading = false;
      state.company = payload;
    },
    [getCompanies.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { increment, decrement, incrementByAmount, updateUserOnList } =
  companiesSlice.actions;

export const companiesReducer = companiesSlice.reducer;
