import { Helmet } from 'react-helmet';
import { useAppDispatch, useAppSelector } from '../../engine/hooks/redux.hooks';
import _ from 'lodash';
import {
  SyncOutlined,
  DeleteOutlined,
  FileAddOutlined,
} from '@ant-design/icons';

import {
  Button,
  Layout,
  Table,
  theme,
  Typography,
  Select,
  Popconfirm,
  Tag,
  Modal,
  Space,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import {
  colors,
  formatCurrency,
  statusOption,
  statusOptionSimple,
} from '../../helpers/constants';
import CustomForm from '../../components/CustomForm';
import { deleteUsers, getCompany, getUsers } from './Users.slice';
import { AppContext, ContextType } from '../../engine/contexts/App.context';
import {
  renderUspi,
  searchStringHelpers,
  StickyWrapper,
} from '../../helpers/utils';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { deleteCompanies } from '../Companies/Companies.slice';
const { Content, Sider, Header, Footer } = Layout;

export function UsersList() {
  const navigate: NavigateFunction = useNavigate();

  const { user } = useContext<ContextType>(AppContext);

  const { users, company, loading } = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [searchName, setSearchName] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [searchCompany, setSearchCompany] = useState(undefined);
  const [selectedRowKeys, SetSelectedRowKeys] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // @ts-ignore
    dispatch(getUsers({ company: user?.company?._id, role: user.role }));
    if (user?.company) {
      // @ts-ignore
      dispatch(getCompany(user.company._id));
    }
  }, []);

  const columns = [
    {
      title: 'Nom',
      dataIndex: 'lastname',
      key: 'name',
      sorter: (a, b) => {
        if (a.lastname < b.lastname) {
          return -1;
        }
        if (a.lastname > b.lastname) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'Prénom',
      dataIndex: 'firstname',
      key: 'firstname',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Gérance',
      dataIndex: 'company',
      key: 'company',
      render: (company) => company?.name,
    },
    {
      title: 'Téléphone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Statut',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      sorter: (a, b) => {
        return a.role.localeCompare(b.role);
      },
    },
    {
      title: 'USPI',
      dataIndex: 'uspi',
      width: '15%',
      key: 'uspi',
      render: (uspi, u) => {

        if (uspi !== null) {
          return renderUspi(uspi)?.map((e) => (
            <Tag color={'red'} style={{ marginBottom: '4px' }}>
              {e}
            </Tag>
          ));
        } else {
          return null;
        }
      },
    },
    {
      title: 'Action',
      dataIndex: '_id',
      width: '15%',
      render: (_id, record) => {
        return <Button onClick={() => navigate(`/user/${_id}`)}>Editer</Button>;
      },
    },
  ];

  const inputs = [
    {
      parentLabel: null,
      children: [
        {
          label: 'Rechercher (nom, prénom, email)',
          name: 'name',
          value: '',
          type: 'input',
          capitalize: false,
          col: 8,
        },
        {
          label: 'Status',
          name: 'status',
          value: 'all',
          type: 'select',
          selectContent: [
            {
              label: 'Tous',
              value: 'all',
            },
            ...statusOptionSimple,
          ],
          col: 4,
        },
        {
          label: 'Gérances',
          name: 'company',
          allowClear: true,
          type: 'dynamic-select',
          endPoint: `/companies`,
          itemCallback: (item) => {
            return {
              value: item._id,
              label: item.name,
            };
          },
          col: 4,
        },
        {
          type: 'reset',
          col: 4,
        },
      ],
    },
  ];

  const onChangeData = (form, changedFields, allFields) => {
    setSearchName(form.getFieldValue('name'));
    setSearchStatus(form.getFieldValue('status'));
    setSearchCompany(form.getFieldValue('company'));
  };

  const manipulateArraySource = () => {
    const copy = _.cloneDeep(users);

    let filtered = copy.filter((user) => {
      if (searchName !== '') {
        return (
          searchStringHelpers(user.lastname, searchName) ||
          searchStringHelpers(user.firstname, searchName) ||
          searchStringHelpers(user.email, searchName)
        );
      }
      if (searchStatus !== '' && searchStatus !== 'all') {
        return user.status === searchStatus;
      }

      if (searchCompany !== undefined) {
        return user.company?._id === searchCompany;
      }

      return true;
    });

    return filtered.sort((b, a) => {
      if (a.id > b.id) {
        return -1;
      }
      if (b.id > a.id) {
        return 1;
      }
      return 0;
    });
  };

  // @ts-ignore

  const rowSelection = {
    selectedRowKeys,
    selections: true,
    onChange: (r) => {
      SetSelectedRowKeys(r);
    },
  };

  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
    // @ts-ignore
  };

  const hideModalAndDelete = () => {
    setOpen(false);
    // @ts-ignore
    dispatch(deleteUsers(selectedRowKeys));
  };

  return (
    <>
      <Helmet>
        <title>Extranet USPI - Membres</title>
      </Helmet>
      <StickyWrapper>
        <Header
          style={{
            padding: 10,
            background: colorBgContainer,
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography.Title
            style={{
              marginBottom: 0,
              marginLeft: '20px',
              color: ' rgb(0 21 41)',
              fontWeight: 300,
              fontSize: '25px',
            }}
            className={''}
          >
            Gestion des utilisateurs {users.length}
          </Typography.Title>
          <Space direction={'horizontal'}>
            <Button
              onClick={() => {
                navigate('/user/new');
              }}
              type={'primary'}
              style={{
                justifySelf: 'flex-start',
                display: 'flex',
              }}
              icon={<FileAddOutlined style={{ marginTop: '5px' }} />}
            >
              Ajouter un utilisateur
            </Button>
            <Button
              onClick={showModal}
              style={{ justifySelf: 'flex-start', display: 'flex' }}
              disabled={selectedRowKeys.length === 0}
              icon={<DeleteOutlined style={{ marginTop: '5px' }} />}
            >
              Supprimer la sélection
            </Button>
          </Space>

          <Modal
            title="Attention !"
            open={open}
            onOk={hideModalAndDelete}
            onCancel={hideModal}
            okText="Je confirme"
            cancelText="J'annule"
          >
            <p>{`Vous allez supprimer ${selectedRowKeys.length} utilisateur${
              selectedRowKeys.length > 1 ? 's' : ''
            } !`}</p>
          </Modal>
        </Header>
      </StickyWrapper>
      <Content
        style={{
          margin: '24px 16px 0',
          overflow: 'initial',
          cursor: 'pointer',
        }}
      >
        <div
          style={{
            padding: 24,
            background: colorBgContainer,
          }}
        >
          <CustomForm
            dataSource={inputs}
            labelCol={24}
            wrapperCol={24}
            onChangeData={onChangeData}
            handleSubmit={undefined}
            resetOnFinish={undefined}
            refForm={undefined}
            onValuesChange={undefined}
          />
          <Table
            pagination={{
              hideOnSinglePage: true,
              position: ['bottomRight'],
              defaultPageSize: 10,
              pageSizeOptions: [10, 20, 50, 100, 500, 1000],
              showSizeChanger: true,
            }}
            rowSelection={rowSelection}
            loading={loading}
            dataSource={manipulateArraySource()}
            columns={columns}
            rowKey="_id"
          />
        </div>
      </Content>
    </>
  );
}
