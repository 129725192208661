import {
  Button,
  Card,
  Layout,
  notification,
  Popconfirm,
  Skeleton,
  Table,
  Tag,
  theme,
  Typography,
} from 'antd';
import { Helmet } from 'react-helmet';

import React, { useContext, useEffect, useState } from 'react';
import { useAppDispatch } from '../../engine/hooks/redux.hooks';
import { AppContext, ContextType } from '../../engine/contexts/App.context';
import { DeleteOutlined } from '@ant-design/icons';
import CustomForm from '../../components/CustomForm';
import { statusOption } from '../../helpers/constants';
import { http } from '../../helpers/http';
const { Content, Sider, Header, Footer } = Layout;

export function Help({}) {
  const { user } = useContext<ContextType>(AppContext);

  const [loading, setLoading] = useState(false);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleSubmit = async (data) => {
    setLoading(true);
    let send = await http.post('/users/support', data);

    notification.success({
      message: `Merci pour votre message`,
      description: 'Nous vous répondrons dans les plus brefs délais',
    });
    setLoading(false);
  };

  const inputs = [
    {
      parentLabel: 'Merci de nous contacter via ce formulaire',
      children: [
        {
          label: 'Nom & Prénom',
          placeholder: 'Nom & Prénom',
          name: 'name',
          capitalize: false,
          value: user.lastname + ' ' + user.firstname,
          type: 'input',
          required: true,
          col: 12,
        },
        {
          label: 'Email',
          name: 'email',
          value: user.email,
          type: 'email',
          col: 12,
          required: true,
        },
        {
          label: 'Téléphone',
          name: 'phone',
          value: '+41' + user.phone,
          type: 'number',
          col: 12,
          required: false,
        },
        {
          required: true,
          label: 'Gérance',
          name: 'type',
          value: user?.company?.name,
          type: 'input',
          col: 12,
        },
        {
          required: true,
          label: 'Sujet',
          name: 'sujet',
          value: null,
          type: 'select',
          selectContent: [
            { label: 'Assistance technique', value: 'Assistance technique' },
            { label: 'Demande de document', value: 'Demande de document' },
            { label: "Changement d'uspi", value: "Changement d'uspi" },
            { label: 'Autre', value: 'Autre' },
          ],
          col: 24,
        },
        {
          required: true,
          label: 'Message',
          name: 'message',
          value: null,
          type: 'textarea',
          col: 24,
        },
      ],
    },
    {
      children: [
        {
          label: 'Envoyer la demande',
          name: 'send',
          value: 'send',
          type: 'submit',
          col: 24,
          align: 'right',
        },
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <title>Extranet USPI - Support</title>
      </Helmet>
      <div>
        <Header
          style={{
            padding: 10,
            background: colorBgContainer,
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography.Title
            style={{
              marginBottom: 0,
              marginLeft: '20px',
              color: ' rgb(0 21 41)',
              fontWeight: 300,
              fontSize: '25px',
            }}
            className={''}
          >
            Support
          </Typography.Title>
        </Header>
        <Content
          style={{
            margin: '20px',
            overflow: 'initial',
          }}
        >
          <Card loading={loading}>
            <CustomForm
              dataSource={inputs}
              labelCol={24}
              wrapperCol={24}
              handleSubmit={handleSubmit}
              resetOnFinish={undefined}
              refForm={undefined}
              onValuesChange={undefined}
            />
          </Card>
        </Content>
      </div>
    </>
  );
}
