/* eslint-disable */

import { entryShown } from './Cahier.pdf';
import { renderCharge, renderStatus } from './CahierSubChapter.panel';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Uspi_membre from '../../assets/uspi_rvb.png';
import LatoLight from '../../assets/Lato-Light.ttf';
import LatoThin from '../../assets/Lato-Thin.ttf';
import LatoRegular from '../../assets/Lato-Regular.ttf';
import LatoBlack from '../../assets/Lato-Black.ttf';
import { Button, notification } from 'antd';

const defaultValueNote = (cahier, entryId) => {
  let prestation = cahier.prestations.find((e) => e.entry === entryId);
  if (prestation === undefined) {
    return '';
  } else {
    return prestation.note;
  }
};
export const createPdfBlob = ({
  cahier,
  cahierModelData,
  company,
  totalEntries,
  imgWidth,
  imgHeight,
  progressCallback,
}) => {
  const doc = new jsPDF();

  const addPageBreakIfNeeded = (doc, currentY) => {
    const lineHeight = doc.getLineHeight();
    const availableSpace = doc.internal.pageSize.height - currentY;
    const titleHeight = 20; // Adjust this value according to your title's height
    if (availableSpace < titleHeight + lineHeight + 50) {
      doc.addPage();
      currentY = 20;
    }

    return currentY;
  };

  // Set font styles
  doc.setFontSize(9);
  doc.setTextColor('#363636');

  // Render company information and logo
  if (company.logo !== null) {
    doc.addImage(
      `data:${company.logo_type};base64,${company.logo}`,
      company.logo_type,
      10,
      5,
      imgWidth,
      imgHeight,
    );
  }

  const phoneNumber = company?.phone ? `+41${company.phone}` : '';
  const companyInfo = `${company?.name || ''}\n${
    company?.address?.street || ''
  } ${company?.address?.number || ''}\n${company?.address?.npa || ''} ${
    company?.address?.city || ''
  }\n${company?.address?.canton || ''}\n---\n${phoneNumber}\n${
    company?.email || ''
  }`;

  doc.text(companyInfo, 50, 10);

  doc.addImage(
    Uspi_membre,
    'PNG', // Specify the image format (e.g., 'JPEG', 'PNG', etc.)
    doc.internal.pageSize.getWidth() - 1920 * 0.03 - 10,
    5,
    1920 * 0.03,
    675 * 0.03,
  );

  // Render cahier name and description
  doc
    .setFontSize(20)
    .text(cahier?.name, doc.internal.pageSize.getWidth() / 2, 60, {
      align: 'center',
      underline: true,
    });

  // Split the entry text into lines based on the column width
  const lines = doc.splitTextToSize(
    cahier?.description || '',
    doc.internal.pageSize.getWidth() * 2 - 100,
  );

  doc.setFontSize(12);
  doc.text(lines, 10, 70);
  doc.setDrawColor('#e5e5e5');
  doc
    .setLineWidth(0.3)
    .line(
      10,
      lines.length * 10 + 70,
      doc.internal.pageSize.getWidth() - 20,
      lines.length * 10 + 70,
    ); // Add borderBottom style

  // doc.setFontSize(9).text(cahier.description, 10, 150, {
  //   align: 'center',
  //   underline: true,
  // });

  // Render categories, chapters, subchapters, and entries

  let currentY = lines.length * 10 + 80; // Initial Y position for categories

  const pageHeight = doc.internal.pageSize.getHeight(); // Get the height of the page
  const columnWidth = doc.internal.pageSize.getWidth() / 2.5; // Adjust the width as needed

  let loadedEntries = 0;
  const { numerotationAutomatic } = cahier;

  // Render categories, chapters, subchapters, and entries
  cahierModelData.categories.forEach((category, i) => {
    if (i > 0) {
      doc.addPage();
      currentY = 20; // Reset Y position on the new page
    }
    doc.setFont(undefined, 'bold');
    doc
      .setFontSize(16)
      .text(
        numerotationAutomatic
          ? `Chapitre ${i + 1}. ${category.name}`
          : `${'Chapitre'} ${category.n} ${category.name}`,
        10,
        currentY,
      );
    currentY += 10; // Increment Y position for chapters

    category.chapters.forEach((chapter, chapIndex) => {
      currentY = addPageBreakIfNeeded(doc, currentY);
      doc.setFontSize(10).setTextColor('#000000').setFillColor('#dadada');

      doc.setFont(undefined, 'bold');
      doc.text(
        numerotationAutomatic
          ? `${i + 1}.${chapIndex + 1}. ${chapter.name}`
          : `${chapter.n} ${chapter.name}`,
        10,
        currentY + 5,
      );
      currentY += 10; // Increment Y position for subchapters

      const renderEntry = (entry) => {
        // If cahier.showType === false dont add the renderStatus

        const entryText = [
          cahier?.showType ? renderStatus(entry.status) : '',
          renderCharge(entry.charge),
          entry.note,
          entry.extra,
          defaultValueNote(cahier, entry._id),
        ].join(' ');

        // Split the entry text into lines based on the column width
        const lines = doc.splitTextToSize(entry.name, columnWidth);

        const entryTextLines = doc.splitTextToSize(entryText, columnWidth);

        // Check if content exceeds the available space on the page
        if (currentY + 10 > pageHeight - 20) {
          doc.addPage();
          currentY = 20; // Reset Y position on the new page
        }

        doc.setFontSize(10).setTextColor('#000000');
        doc.setFont(undefined, 'normal');
        doc.text(lines, 16, currentY + 5);

        if (entryTextLines.length > 1) {
          // currentY += entryTextLines.length * 5;
        }

        entryTextLines.map((text, index) => {
          let addWidhSecondLine = 0;
          if (index > 0) {
            if (cahier?.showType) {
              addWidhSecondLine = 13;
            } else {
              addWidhSecondLine = 4;
            }
          }

          doc.text(text, 18 + columnWidth + addWidhSecondLine, currentY + 5);
          if (index < entryTextLines.length - 1) {
            currentY += 5;
          }
        });

        doc.setDrawColor('#e5e5e5');
        doc
          .setLineWidth(0.3)
          .line(
            16,
            currentY + lines.length * 7,
            doc.internal.pageSize.getWidth() - 20,
            currentY + lines.length * 7,
          ); // Add borderBottom style

        loadedEntries++;

        // Calculate the progress as a percentage
        const progress = (loadedEntries / totalEntries) * 100;

        // Call the progress callback with the current progress
        if (progressCallback) {
          progressCallback(progress);
        }

        currentY += lines.length * 7; // Increment Y position based on the number of lines
      };

      if (chapter?.entries?.length !== 0) {
        chapter.entries.map((entry) => renderEntry(entry)); // Trick for render chapter entry
      } else {
        chapter.subChapters.forEach((sub, subIndex) => {
          currentY = addPageBreakIfNeeded(doc, currentY);
          doc.setFontSize(10).setTextColor('#000000').setFillColor('#ecebeb');

          doc.rect(10, currentY, doc.internal.pageSize.getWidth() - 30, 8, 'F');
          doc.setFont(undefined, 'bold');
          doc.text(
            numerotationAutomatic
              ? `${i + 1}.${chapIndex + 1}.${subIndex + 1}. ${sub.name}`
              : `${sub.n} ${sub.name}`,
            13,
            currentY + 5,
          );
          currentY += 10; // Increment Y position for table

          sub.entries
            .filter((entry) => entryShown(cahier, entry._id))
            .forEach((entry) => renderEntry(entry));

          currentY += 5; // Add extra space after subchapter entries
        });
      }
    });
  });

  const totalPages = doc.internal.getNumberOfPages(); // Get the total number of pages

  // Add page numbers to each page
  for (let i = 1; i <= totalPages; i++) {
    doc.setPage(i); // Set the current page
    doc.setFontSize(8).setTextColor('#000000');
    doc.text(
      `Page ${i} sur ${totalPages}`,
      doc.internal.pageSize.getWidth() / 2 - 10,
      doc.internal.pageSize.getHeight() - 10,
    );
  }

  // Generate a blob from the document
  return new Promise((resolve, reject) => {
    doc
      .save(`cahier_${cahier.name}.pdf`, { returnPromise: true })
      .then((blob) => {
        resolve(blob);
      });
  });
};

export const handleDownload = async ({ cahier, cahierModelData, company }) => {
  try {
    let blob = null;

    const isChapterHidden = (chapterId) => {
      if (cahier !== null) {
        return cahier?.chaptersHidden?.some((e) => e === chapterId);
      }
    };

    let totalEntries = 0;

    let filteredEmptyCategories = cahierModelData.categories.map((cat) => {
      return {
        ...cat,
        chapters: cat.chapters
          .filter((chap) => !isChapterHidden(chap._id))
          .map((chapter) => {
            const subChaptersFiltered = chapter.subChapters.filter(
              (sub, subIndex) => {
                const allChildHidden = sub.entries.every(
                  (entry) => !entryShown(cahier, entry._id),
                );

                return !allChildHidden && sub.entries.length > 0;
              },
            );

            // filter sub
            return {
              ...chapter,
              subChapters: subChaptersFiltered,
            };
          }),
      };
    });

    let newCahierModelData = {
      ...cahierModelData,
      categories: filteredEmptyCategories,
    };

    if (company.logo !== null) {
      const img = new Image();

      // Create a Promise to wait for the image to load
      const imageLoadedPromise = new Promise(async (resolve) => {
        img.onload = resolve;
      });

      img.src = `data:${company.logo_type};base64,${company.logo}`; // Set the source of the image
      const desiredWidth = 30;
      // Wait for the image to load before adding it to the PDF
      imageLoadedPromise.then(async () => {
        const aspectRatio = img.width / img.height; // Calculate the aspect ratio

        const imgWidth = desiredWidth; // Set the width of the image
        const imgHeight = imgWidth / aspectRatio; // Calculate the height based on the aspect ratio

        blob = await createPdfBlob({
          cahier,
          cahierModelData: newCahierModelData,
          company,
          imgHeight,
          imgWidth,
          totalEntries,
          progressCallback: (progress) => {
            // console.log(progress);
          },
        });
      });
    } else {
      blob = await createPdfBlob({
        cahier,
        cahierModelData: newCahierModelData,
        company,
        totalEntries,
        progressCallback: (progress) => {
          // console.log(`Progress: ${progress}%`);
        },
      });
    }
  } catch (error) {
    console.error('Error creating PDF:', error);
  }
};
