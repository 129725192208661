/**
 *
 * AuthRoute
 *
 */

import React, { useContext } from 'react';
import { Navigate, useLocation, Outlet, useParams } from 'react-router-dom';
import { AppContext } from '../contexts/App.context';

function AuthRoute({ children, redirectTo, routeProps }) {
  const app = useContext(AppContext);
  const location = useLocation();

  // redirect ghost
  if (!app.isConnected) {
    // @ts-ignore
    return <Navigate to={{ pathname: `/auth`, state: { from: location } }} />;
  }

  const protectedRoutes = [
    {
      path: '/users',
      access: ['admin'],
    },
    {
      path: '/companies',
      access: ['admin'],
    },
    {
      path: '/documents/fribourg',
      uspi: 'fr',
    },
  ];

  const userRole = app.user.role;
  const pathName = location.pathname;

  // Check if the current route is a protected route
  const isProtectedRoute = protectedRoutes.some(
    (route) => route.path === pathName,
  );

  // Check if the user has access to the current protected route
  let hasAccess = true;

  const route = protectedRoutes.find((route) => route.path === pathName);

  if (isProtectedRoute && route.access && !route.access?.includes(userRole)) {
    hasAccess = false;
  }

  if (isProtectedRoute && !app.user.uspi.includes(route.uspi)) {
    if (app.user.role === 'user') {
      hasAccess = false;
    }
  }

  if (!hasAccess) {
    return <Navigate to={{ pathname: `/` }} />;
  }

  return children;
}

// : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}

AuthRoute.propTypes = {};

export default AuthRoute;
