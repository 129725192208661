// @ts-nocheck
import React from 'react';
import {
  updateEntryValue,
  updatePrestation,
  updatePrestationsShown,
} from './CahierModel.slice';
import { useAppDispatch, useAppSelector } from '../../engine/hooks/redux.hooks';

import { Checkbox, Col, Collapse, Input, Radio, Row, Select } from 'antd';
import CahierSubChapterCahierEntry, {
  MemoizedCahierSubChapterEntry,
} from './CahierSubChapter.cahier.entry';
import { MemoizedCahierSubChapterModel } from './CahierSubChapter.model.entry';
const { Panel } = Collapse;

export const renderCharge = (status) => {
  switch (status) {
    case 'owner':
      return 'A charge du propriétaire';
    case 'tenant':
      return 'A charge du locataire ';
  }
};

export const renderStatus = (status) => {
  switch (status) {
    case 'included':
      return 'Inclus';
    case 'unrealised':
      return 'Non réalisé';
    case 'addition':
      return 'En sus';
  }
};

export const CahierSubChapterPanel = (props) => {
  const {
    catIndex,
    level,
    levelChapter,
    indexChapter,
    isModel,
    subChapter,
    chapterHidden,
    levelSubChapter,
    indexSubChapter,
    chapter,
    numerotationAutomatic,
    category,
  } = props;
  const { cahierModelData, loadingSave, cahier } = useAppSelector(
    (state) => state.cahierModel,
  );

  let isDirectEntries = chapter._id === subChapter._id;

  const dispatch = useAppDispatch();

  const onChangeEntryShwon = ({ entry, shown = true, note = '' }) => {
    dispatch(updatePrestation({ entry, shown, note }));
  };

  const onChangeValue = ({ levels, type, value }) => {
    dispatch(updateEntryValue({ levels, type, value }));
  };

  const entryShown = (entryId) => {
    let prestation = cahier?.prestations?.find((e) => e.entry === entryId);
    if (prestation === undefined) {
      return true;
    } else {
      return prestation.shown;
    }
  };

  const defaultValueNote = (entryId) => {
    let prestation = cahier?.prestations?.find((e) => e.entry === entryId);
    if (prestation === undefined) {
      return '';
    } else {
      return prestation.note;
    }
  };

  const onValuesChange = () => {};

  const labelsEntries = () => {
    return (
      <Row
        style={{ width: '100%', marginBottom: 5 }}
        gutter={[15, 15]}
        className={'labelsEntries'}
      >
        <Col span={8} style={{ marginLeft: '0px' }}>
          Prestation
        </Col>
        <Col span={6}>Type</Col>
        <Col span={4}>A Charge de</Col>
        <Col span={4}>Montant</Col>
        <Col span={2}>Valeur</Col>
      </Row>
    );
  };

  const labelsEntriesCahier = () => {
    return (
      <Row
        style={{ width: '100%', marginBottom: 5 }}
        gutter={[15, 15]}
        className={'labelsEntries'}
      >
        <Col span={8} style={{ marginLeft: '0px' }}>
          Prestation
        </Col>
        <Col span={6}>Type</Col>
        <Col span={10}>Remarque</Col>
      </Row>
    );
  };

  const onClickToggleButton = (_id) => {
    if (toggleLevel.find((i) => i === _id) === undefined) {
      setToggleLevel([...toggleLevel, _id]);
    } else {
      setToggleLevel(toggleLevel.filter((e) => e !== _id));
    }
  };

  const toggleAllEntry = (e) => {
    // e.preventDefault();
    e.stopPropagation();

    dispatch(
      updatePrestationsShown({
        shown: e.target.checked,
        entries: subChapter.entries.map((e) => e._id),
      }),
    );
  };

  const allChildChecked = () => {
    let all =
      isDirectEntries || subChapter.entries.every((e) => entryShown(e._id));
    return all;
  };

  const allChildHidden = () => {
    let all =
      isDirectEntries || subChapter.entries.some((e) => entryShown(e._id));
    return all;
  };

  const areallChildChecked = !isModel && allChildChecked(subChapter._id);

  const renderItemsModel = () => {
    return (
      <Row
        style={{ width: '100%', opacity: chapterHidden ? 0.7 : 1 }}
        gutter={[5, 5]}
      >
        {labelsEntriesCahier()}
        {subChapter.entries.map((item, indexEntry) => {
          const isChecked = entryShown(item._id);

          return (
            <MemoizedCahierSubChapterEntry
              numerotationAutomatic={numerotationAutomatic}
              onChangeEntryShwon={onChangeEntryShwon}
              key={item._id}
              defaultValueNote={defaultValueNote}
              isChecked={isChecked}
              renderCharge={renderCharge}
              renderStatus={renderStatus}
              entry={item}
            />
          );
        })}
      </Row>
    );
  };

  const renderItemsNotModel = () => {
    return (
      <Row style={{ width: '100%' }} gutter={[5, 5]}>
        {labelsEntries()}
        {subChapter.entries.map((entry, indexEntry) => {
          const levels = isDirectEntries
            ? [category._id, chapter._id, entry._id]
            : [category._id, chapter._id, subChapter._id, entry._id];

          return (
            <MemoizedCahierSubChapterModel
              levels={levels}
              numerotationAutomatic={numerotationAutomatic}
              onChangeEntryShwon={onChangeEntryShwon}
              key={entry._id}
              onChangeValue={onChangeValue}
              defaultValueNote={defaultValueNote}
              renderCharge={renderCharge}
              renderStatus={renderStatus}
              entry={entry}
            />
          );
        })}
      </Row>
    );
  };

  if (isDirectEntries) {
    return isModel ? renderItemsNotModel() : renderItemsModel();
  } else {
    return (
      <Panel
        {...props}
        extra={<></>}
        header={
          <span
            style={{
              opacity: isModel
                ? 1
                : allChildHidden() && !chapterHidden
                ? 1
                : 0.5,
            }}
          >
            {!isModel && (
              <Checkbox
                style={{ marginRight: 10 }}
                checked={areallChildChecked}
                onClick={(e) => toggleAllEntry(e, subChapter._id)}
              />
            )}
            {numerotationAutomatic
              ? `${level}.${levelChapter}.${levelSubChapter}. ${subChapter.name}`
              : `${subChapter.n} ${subChapter.name}`}
          </span>
        }
        key={subChapter._id}
      >
        {isModel ? renderItemsNotModel() : renderItemsModel()}
      </Panel>
    );
  }
};
