// @ts-nocheck

import React from 'react';
import { Checkbox, Col, Input } from 'antd';

const CahierSubChapterCahierEntry = React.memo(
  ({
    entry,
    onChangeEntryShwon,
    renderCharge,
    defaultValueNote,
    isChecked,
    renderStatus,
  }) => {
    // Component code for rendering an individual entry...

    return [
      <Col
        span={8}
        // key={entry._id}
        style={{
          opacity: isChecked ? 1 : 0.5,
        }}
      >
        <Checkbox
          onChange={(e) => {
            onChangeEntryShwon({
              entry: entry._id,
              shown: e.target.checked,
            });
          }}
          checked={isChecked}
          size={'large'}
          style={{ margin: 5 }}
        />
        {entry.name}
      </Col>,
      <Col
        span={6}
        style={{
          opacity: isChecked ? 1 : 0.5,
        }}
      >
        {renderStatus(entry.status)}
      </Col>,
      <Col
        span={10}
        style={{
          opacity: isChecked ? 1 : 0.5,
        }}
      >
        <span>
          {renderCharge(entry.charge)} {entry.note} {entry.extra}{' '}
        </span>
        <Input
          allowClear
          defaultValue={defaultValueNote(entry._id)}
          onChange={(e) => {
            onChangeEntryShwon({
              entry: entry._id,
              note: e.target.value,
            });
          }}
          size={'small'}
          placeholder={'Remarque'}
        />
      </Col>,
    ];
  },
);

export const MemoizedCahierSubChapterEntry = React.memo(
  CahierSubChapterCahierEntry,
  (prevProps, nextProps) => {
    // Custom comparison function for memoization
    return (
      prevProps.entry.status !== nextProps.entry.status ||
      prevProps.entry.note !== nextProps.entry.note ||
      prevProps.entry.isChecked !== nextProps.entry.isChecked ||
      prevProps.entry.charge !== nextProps.entry.charge ||
      prevProps.entry.extra !== nextProps.entry.extra
    );
  },
);
