import React, { useContext } from 'react';
import { AppContext } from '../engine/contexts/App.context';
import Routing from '../engine/routeur/Routing';
import { Spin } from 'antd';

export const LoadingIndex = () => {
  const { isConnected, ready } = useContext(AppContext);

  if (!ready) {
    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100vh', // set height to 100vh for full viewport height
          display: 'flex',
          justifyContent: 'center', // center horizontally
          alignItems: 'center', // center vertically
        }}
      >
        <Spin size={'large'} />
      </div>
    );
  } else {
    return <Routing />;
  }
};
