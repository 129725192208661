import {
  useAppDispatch,
  useAppSelector,
} from '../../../engine/hooks/redux.hooks';
import _ from 'lodash';
import { SyncOutlined, DeleteOutlined } from '@ant-design/icons';

import {
  Button,
  Layout,
  Table,
  theme,
  Typography,
  Select,
  Popconfirm,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { createUnits, getPosts, increment } from './Units.slice';
import { UnitDetail } from '../UnitDetail/Unit.detail';
import {
  colors,
  formatCurrency,
  statusOption,
  statusOptionSimple,
} from '../../../helpers/constants';
import { clearUnit, getUnit, updateUnit } from '../UnitDetail/Unit.slice';
import CustomForm from '../../../components/CustomForm';
import { AppContext } from '../../../engine/contexts/App.context';
import axios, { AxiosResponse } from 'axios';
const { Content, Sider, Header, Footer } = Layout;

export function UnitsList() {
  const units = useAppSelector((state) => state.units);
  const dispatch = useAppDispatch();

  // const context = useContext(AppContext);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const deleteUnits = async () => {};

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      },
      width: '10%',
    },
    {
      title: 'Nom',
      dataIndex: 'info.name',
      key: 'name',
      render: (n, record) => record.info.name,
      width: '15%',
      sorter: (a, b) => {
        if (a.info.name < b.info.name) {
          return -1;
        }
        if (a.info.name > b.info.name) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: '15%',
      key: 'type',
      render: (type) => {
        if (type === 'selling') {
          return 'Vente';
        } else {
          return 'Location';
        }
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '15%',

      render: (s, record) => {
        return (
          <Select
            value={s}
            style={{ width: 120 }}
            // onChange={(newValue) => console.log(newValue)}
            className={'colorfull'}
          >
            {statusOption.map((option) => (
              <Select.Option key={option?.value} value={option?.value}>
                {option?.label()}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: 'Etage',
      dataIndex: 'floor',
      key: 'floor',
      width: '15%',

      render: (n, record) => record.info.floor,
    },
    {
      title: 'Pièces',
      width: '15%',

      dataIndex: 'nbPieces',
      key: 'nbPieces',
      render: (n, record) => record.info.numberRoom,
    },
    {
      title: 'Prix',
      width: '15%',

      dataIndex: 'price',
      key: 'price',
      render: (n, record) => formatCurrency(record.info.price),
    },
  ];

  const inputs = [
    {
      parentLabel: null,
      children: [
        {
          label: 'Id',
          name: 'id',
          value: '',
          type: 'input',
          capitalize: false,
          col: 4,
        },
        {
          label: 'Nom',
          name: 'name',
          value: '',
          type: 'input',
          capitalize: false,
          col: 4,
        },
        {
          label: 'Type',
          name: 'type',
          value: 'all',
          type: 'select',
          selectContent: [
            { label: 'Tous', value: 'all' },
            { label: 'Location', value: 'renting' },
            { label: 'Vente', value: 'selling' },
          ],
          col: 4,
        },
        {
          label: 'Status',
          name: 'status',
          value: 'all',
          type: 'select',
          selectContent: [
            {
              label: 'Tous',
              value: 'all',
            },
            ...statusOptionSimple,
          ],
          col: 4,
        },
      ],
    },
  ];

  return (
    <>
      <Header
        style={{
          padding: 10,
          background: colorBgContainer,
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography.Title
          style={{
            marginBottom: 0,
            marginLeft: '20px',
            color: ' rgb(0 21 41)',
            fontWeight: 300,
            fontSize: '25px',
          }}
          className={''}
        >
          Gestion des lots
        </Typography.Title>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <Popconfirm
            title="Confirmer la suppression des lots ?"
            onConfirm={deleteUnits}
            // onOpenChange={() => console.log('open change')}
          >
            <Button
              style={{ justifySelf: 'flex-start', display: 'flex' }}
              loading={units.loading}
              disabled={units.loading}
              icon={<DeleteOutlined style={{ marginTop: '5px' }} />}
            >
              Vider les lots
            </Button>
          </Popconfirm>
        </div>
      </Header>
      <Content
        style={{
          margin: '24px 16px 0',
          overflow: 'initial',
          cursor: 'pointer',
        }}
      >
        <div
          style={{
            padding: 24,
            background: colorBgContainer,
          }}
        >
          <CustomForm
            dataSource={inputs}
            labelCol={24}
            wrapperCol={24}
            resetOnFinish={undefined}
            refForm={undefined}
            onValuesChange={undefined}
            handleSubmit={undefined}
          />
          <Table
            pagination={{
              hideOnSinglePage: true,
              position: ['bottomRight'],
              defaultPageSize: 10,
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
            }}
            dataSource={[]}
            columns={columns}
            rowKey="_id"
          />
        </div>
      </Content>
    </>
  );
}
