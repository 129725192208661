// @ts-nocheck

import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import {
  UploadOutlined,
  UserOutlined,
  UserSwitchOutlined,
  VideoCameraOutlined,
  HomeOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  InsertRowLeftOutlined,
  DashboardOutlined,
  LineChartOutlined,
} from '@ant-design/icons';

import {
  MdDesignServices,
  MdOutlineDocumentScanner,
  MdInfo,
} from 'react-icons/md';
import { IoMdBusiness } from 'react-icons/io';
import { HiOutlineUserGroup } from 'react-icons/hi';
import {
  GrDocumentText,
  GrCircleInformation,
  GrDocumentUpdate,
  GrDocumentVerified,
} from 'react-icons/gr';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext, ContextType } from '../engine/contexts/App.context';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import Logo from '../assets/logo_uspi_suisse.svg';

const { Header, Content, Sider } = Layout;

export const SiderNavigation = () => {
  const navigate: NavigateFunction = useNavigate();

  const { logOut, user } = useContext<ContextType>(AppContext);

  const [menuItem, setMenuItem] = useState(window.location.pathname);

  // @ts-ignore
  const topMenu: MenuProps['items'] = [
    {
      key: '/',
      label: 'Tableau de bord',
      disabled: false,
      icon: React.createElement(DashboardOutlined),
      onClick: () => {
        navigate('/');
      },
    },
    {
      key: '/cahiers',
      label: 'Cahier de prestations',
      icon: React.createElement(MdDesignServices),
      onClick: () => {
        navigate('/cahiers');
      },
    },
    {
      key: '/documents/meeting',
      onClick: () => {
        navigate('/documents/meeting');
      },
      label: 'Documents assemblée',
      disabled: false,
      icon: <GrDocumentUpdate className={'grometIcon'} />,
    },
    {
      key: '/documents/uspi',
      label: 'USPI Infos',
      disabled: false,
      icon: <MdInfo />,
      onClick: () => {
        navigate('/documents/uspi');
      },
    },
    {
      key: '/documents/utils',
      label: 'Documents utiles',
      disabled: false,
      icon: <GrDocumentVerified className={'grometIcon'} />,
      onClick: () => {
        navigate('/documents/utils');
      },
    },
    {
      key: '/documents/fribourg',
      label: 'Documents FR',
      disabled: false,
      uspi: 'fr',
      icon: React.createElement(MdOutlineDocumentScanner),
      onClick: () => {
        navigate('/documents/fribourg');
      },
    },
  ];

  const menuUser: MenuProps['items'] = [
    {
      key: '/user',
      label: 'Mon compte',
      disabled: false,
      icon: <UserOutlined />,
      onClick: () => {
        navigate('/user');
      },
    },
    {
      key: '/users',
      label: 'Gestion membres',
      disabled: false,
      role: 'admin',
      onClick: () => {
        navigate('/users');
      },
      icon: (
        <HiOutlineUserGroup
          size={18}
          style={{ marginLeft: 0, marginRight: -5 }}
        />
      ),
    },
    {
      key: '/companies',
      label: 'Gestion gérances',
      disabled: false,
      role: 'admin',
      onClick: () => {
        navigate('/companies');
      },
      icon: (
        <IoMdBusiness size={18} style={{ marginLeft: 0, marginRight: -5 }} />
      ),
    },

    {
      key: '/help',
      label: 'Support',
      onClick: async (d) => {
        navigate('/help');
      },
      icon: React.createElement(QuestionCircleOutlined),
    },
    {
      key: '/logout',
      label: 'Se déconnecter',
      icon: React.createElement(LogoutOutlined),
      onClick: async (d) => {
        await logOut();
        navigate('/auth');
      },
    },
  ];

  const filterByRoleUspi = (e) => {
    if (e.uspi !== undefined) {
      return user.uspi.includes(e.uspi) || user.role === 'admin';
    }

    if (e.role !== undefined && e.role === 'admin') {
      return user.role === 'admin' || user.role === 'superadmin';
    }

    return true;
  };

  return (
    <Sider
      width={220}
      style={{
        background: '#262626',
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <div style={{ backgroundColor: 'white', padding: '22px' }}>
        <a onClick={() => navigate('/')}>
          <img
            src={Logo}
            style={{
              width: '120px',
              margin: '0 auto',
              display: 'block',
              marginTop: '0px',
            }}
          />
        </a>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 80px)',
          justifyContent: 'space-between',
        }}
      >
        <Menu
          style={{ background: '#262626' }}
          theme="dark"
          mode="inline"
          onSelect={(f) => {
            setMenuItem(f.key);
          }}
          selectedKeys={menuItem}
          defaultSelectedKeys={['dashboard']}
          items={topMenu.filter(filterByRoleUspi)}
        />

        <Menu
          style={{ background: '#262626' }}
          theme="dark"
          mode="inline"
          onSelect={(f) => {
            setMenuItem(f.key);
          }}
          selectedKeys={menuItem}
          items={menuUser.filter(filterByRoleUspi)}
        />
      </div>
    </Sider>
  );
};
