import React, { useState, useEffect, useContext } from 'react';
import { useAppDispatch, useAppSelector } from '../../engine/hooks/redux.hooks';
import { saveAs } from 'file-saver';
import LatoLight from '../../assets/Lato-Light.ttf';
import LatoThin from '../../assets/Lato-Thin.ttf';
import LatoRegular from '../../assets/Lato-Regular.ttf';
import LatoBlack from '../../assets/Lato-Black.ttf';
import Uspi_membre from '../../assets/uspi_rvb.png';

import {
  clearCahierModel,
  getCahierEntries,
  getCahierModel,
  updateCahier,
  updateCahierModel,
} from './CahierModel.slice';

import {
  Font,
  Page,
  Text,
  View,
  Document,
  Image,
  pdf,
  StyleSheet,
  PDFViewer,
  render,
} from '@react-pdf/renderer';

import { renderCharge, renderStatus } from './CahierSubChapter.panel';
import ReactDOM from 'react-dom';
import { clearCompany, getCompany } from '../Company/Company.slice';
import { AppContext } from '../../engine/contexts/App.context';
import { notification } from 'antd';
import moment from 'moment';
import { handleDownload } from './Cahier.pdfkit';

const styles = StyleSheet.create({
  bolder: {
    fontSize: 10,
    fontFamily: 'LatoRegular',
    fontWeight: 500,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 9,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    fontFamily: 'LatoLight',
    color: '#363636',
    fontSize: 9,
    padding: 30,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  container: {},
  product: {
    width: '100%',
    borderBottom: '1px solid #D2D2D2',
    paddingTop: 3,
    paddingBottom: 2,
    marginBottom: 0,
    flexDirection: 'row',
  },
  name: { width: '50%', flexDirection: 'row' },
  extra: { width: '20%' },
  note: { width: '20%' },
  status: { width: '10%' },
  square: { height: 20, width: 20, borderRadius: '50%', marginRight: 5 },
  text: {
    fontFamily: 'LatoLight',
  },
  title: {
    fontFamily: 'LatoBlack',
    fontWeight: 900,
  },
  category: {
    marginTop: 30,
    fontFamily: 'LatoBlack',
    fontWeight: 900,
    fontSize: 16,
    marginBottom: 30,
  },
  chapter: {
    marginTop: 12,
    width: '100%',
    fontFamily: 'LatoRegular',
    fontWeight: 500,
    fontSize: 10,
    marginLeft: 0,
    borderRadius: 3,
    padding: 3,
    backgroundColor: '#dadada',
  },
  subchapter: {
    width: '100%',
    fontFamily: 'LatoRegular',
    fontWeight: 500,
    fontSize: 10,
    marginLeft: 10,
    borderRadius: 3,
    padding: 3,
    backgroundColor: '#ecebeb',
  },
  entryTitle: {},
  logo: {
    width: '100%',
  },
  filters: {
    width: '30%',
    flexDirection: 'row',
  },
  filter: {
    textAlign: 'center',
    width: '50%',
    backgroundColor: '#d3d3d3',
    marginLeft: 4,
    borderRadius: 3,
    padding: 3,
  },
});

Font.register({
  family: 'LatoLight',
  format: 'truetype',
  src: LatoLight,
});

Font.register({
  family: 'LatoRegular',
  format: 'truetype',
  src: LatoRegular,
});

Font.register({
  family: 'LatoBlack',
  format: 'truetype',
  src: LatoBlack,
});

Font.register({
  family: 'LatoThin',
  format: 'truetype',
  src: LatoThin,
});

const renderEntry = (entry, cahier) => {
  //  {renderCharge(entry.charge)} {entry.note} {entry.extra}{' '}

  /*  const entryText = `${entry.name} ${renderCharge(entry.charge)} ${
    entry.note
  } ${entry.extra} ${defaultValueNote(cahier, entry._id)}`;*/

  return (
    <View style={[styles.product, { marginLeft: 15 }]}>
      <Text style={[styles.entryTitle, { width: '50%' }]}>{entry.name}</Text>
      <Text style={[styles.entryTitle, { width: '20%', paddingLeft: 5 }]}>
        {renderStatus(entry.status)}
      </Text>
      <Text style={[styles.entryTitle, { width: '20%', paddingLeft: 5 }]}>
        {renderCharge(entry.charge)} {entry.note} {entry.extra}
      </Text>

      <Text style={[styles.entryTitle, { width: '10%' }]}>
        {defaultValueNote(cahier, entry._id)}
      </Text>
    </View>
  );
};

const defaultValueNote = (cahier, entryId) => {
  let prestation = cahier.prestations.find((e) => e.entry === entryId);
  if (prestation === undefined) {
    return '';
  } else {
    return prestation.note;
  }
};
const renderSub = (sub, { catIndex, chapIndex, subIndex }, cahier) => {
  const allChildHidden = () => {
    let all = sub.entries.every((e) => entryShown(cahier, e._id) === false);
    return all;
  };

  if (allChildHidden() || sub.entries.length === 0) {
    return null;
  } else {
    return (
      <>
        <Text style={[styles.subchapter, { marginBottom: 12, marginTop: 12 }]}>
          {catIndex}.{chapIndex + 1}.{subIndex + 1}. {sub.name}
        </Text>
        {sub.entries.map(
          (entry) =>
            entryShown(cahier, entry._id) && renderEntry(entry, cahier),
        )}
      </>
    );
  }
};

export const entryShown = (cahier, entryId) => {
  let prestation = cahier.prestations.find((e) => e.entry === entryId);
  if (prestation === undefined) {
    return true;
  } else {
    return prestation.shown;
  }
};

const MyDocument = ({ cahier, cahierModelData, company }) => {
  const entryShown = (cahier, entryId) => {
    let prestation = cahier.prestations.find((e) => e.entry === entryId);
    if (prestation === undefined) {
      return true;
    } else {
      return prestation.shown;
    }
  };

  const renderChapter = (chapter, { catIndex, chapIndex }) => {
    return (
      <>
        <Text style={styles.chapter}>
          {catIndex}.{chapIndex + 1}. {chapter.name}
        </Text>
        {chapter.subChapters.map((sub, subIndex) =>
          renderSub(sub, { catIndex, chapIndex, subIndex }, cahier),
        )}
      </>
    );
  };

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.section}>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '20%', marginRight: 10 }}>
              <Image
                src={`data:${company.logo_type};base64,${company.logo}`}
                style={styles.logo}
              />
            </View>
            <View style={styles.filters}>
              <Text style={styles.bolder}>
                {company.name}
                {'\n'}
                {company.address.street} {company.address.number}
                {'\n'}
                {company.address.npa} {company.address.city}
                {'\n'}
                {company.address.canton}
                ---
                {'\n'}
                +41{company.phone}
                {'\n'}
                {company.email}
              </Text>
            </View>
            <View
              style={{
                // width: '30%',
                // flexDirection: 'row',
                alignContent: 'flex-end',
                alignItems: 'flex-end',
                padding: 10,
              }}
            >
              <Image
                src={Uspi_membre}
                style={{
                  width: 1920 * 0.06,
                  height: 675 * 0.06,
                }}
              />
            </View>
          </View>

          <Text
            style={{
              textAlign: 'center',
              margin: 20,
              fontSize: 25,
              borderBottom: '1px solid #d2d2d2',
            }}
          >
            {cahier.name}
          </Text>
          <Text style={styles.bolder}>{cahier.description}</Text>

          {cahierModelData.categories.map((category, i) => {
            return (
              <>
                <Text style={styles.category}>
                  Chapitre {i + 1}. {category.name}
                </Text>
                {category.chapters.map((chap, chapIndex) =>
                  renderChapter(chap, { catIndex: i + 1, chapIndex }),
                )}
              </>
            );
          })}
        </View>
        <Text
          style={styles.pageNumber}
          fixed
          render={({ pageNumber, totalPages }) => {
            return pageNumber + '/' + totalPages;
          }}
        ></Text>
      </Page>
    </Document>
  );
};

const useCahierPDF = () => {
  const [state, setState] = useState(null);
  const [generating, setGenerating] = useState(false);

  const { cahierModelData, loading, cahier } = useAppSelector(
    (state) => state.cahierModel,
  );

  const { user } = useContext(AppContext);

  const { companyData } = useAppSelector((state) => state.company);

  const dispatch = useAppDispatch();

  const getCahier = (modelId, cahierId) => {
    setGenerating(true);
    dispatch(getCahierModel(modelId));
    dispatch(getCahierEntries(cahierId));
    dispatch(getCompany(user.company._id));
  };

  useEffect(() => {
    return () => {
      dispatch(clearCahierModel());
      dispatch(clearCompany());
    };
  }, []);

  const generateFile = async () => {
    // ReactDOM.render(
    //   <PDFViewer style={{ flex: 1, width: 1500, height: 500 }}>
    //     <MyDocument cahier={cahier} cahierModelData={cahierModelData} />
    //   </PDFViewer>,
    //   document.getElementById('pdf'),
    // );
    /*
    render(
      <MyDocument cahier={cahier} cahierModelData={cahierModelData} />,
      `${__dirname}/example.pdf`,
    );*/

    const blob = await pdf(
      <MyDocument
        cahier={cahier}
        cahierModelData={cahierModelData}
        company={companyData}
      />,
    ).toBlob();
    saveAs(blob, `cahier_${cahier.name}.pdf`);

    setGenerating(false);

    dispatch(
      updateCahier({
        ...cahier,
        exported: [...cahier.exported, moment().format()],
      }),
    );

    notification.success({
      message: `PDF généré`,
    });
  };

  const pdfJsGenerate = async () => {
    await handleDownload({
      cahier,
      cahierModelData,
      company: companyData,
    });

    setGenerating(false);
  };

  useEffect(() => {
    if (
      loading === false &&
      cahierModelData !== null &&
      cahier !== null &&
      companyData !== null &&
      generating === true
    ) {
      // generateFile();
      pdfJsGenerate();
    }
  }, [cahierModelData, loading, cahier, generating, companyData]);

  const loadingPdf = loading;

  // Return any values, functions, or data that needs to be accessed externally
  return {
    state,
    loadingPdf: loadingPdf || generating,
    getCahier,
  };
};

export default useCahierPDF;
