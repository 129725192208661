export const renderUspi = (uspis) => {
    
    if (uspis !== null) {
        return uspis.map((u) => {
          return listCantons.find((e) => e.value === u)?.label || '';
        });
    } else {
        return ''
    }
    

};

export const searchStringHelpers = (
  stringToSearchIn: string,
  stringRequested: string,
) => {
  const normalizedSearchName = stringRequested
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  const normalizedCName = stringToSearchIn
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

  // Perform a case-insensitive search using indexOf
  return (
    normalizedCName.toLowerCase().indexOf(normalizedSearchName.toLowerCase()) >=
    0
  );
};

export const listCantons = [
  { value: 'fr', label: 'Fribourg', color: '#000000' },
  { value: 'vd', label: 'Vaud', color: '#18A74E' },
  { value: 'vs', label: 'Valais', color: '#E9403C' },
  { value: 'ne', label: 'Neuchâtel', color: '#83A94D' },
  { value: 'ge', label: 'Genève', color: '#FFD300' },
  { value: 'ju', label: 'Jura', color: '#E8423F' },
  { value: 'be', label: 'Berne', color: '#0000EC' },
  { value: 'null', label: 'Aucun', color: '#FF0001' },
];

export const listCantonsGerance = [
  { value: 'fr', label: 'Fribourg', color: '#000000' },
  { value: 'vd', label: 'Vaud', color: '#18A74E' },
  { value: 'vs', label: 'Valais', color: '#E9403C' },
  { value: 'ne', label: 'Neuchâtel', color: '#83A94D' },
  { value: 'ge', label: 'Genève', color: '#FFD300' },
  { value: 'ju', label: 'Jura', color: '#E8423F' },
  { value: 'be', label: 'Berne', color: '#0000EC' },
  { value: '', label: 'Tous', color: '#FF0001' },
];
export const statusUser = [
  { value: 'inactive', label: 'Inactif' },
  { value: 'active', label: 'Activé' },
  { value: 'disabled', label: 'Désactivé' },
];

export const roleUser = [
  { value: 'admin', label: 'Admin' },
  { value: 'user', label: 'Utilisateur' },
];

export const StickyWrapper = ({ children }) => {
  return (
    <>
      <div
        style={{
          zIndex: 1000,
          position: 'fixed',
          top: 0,
          width: 'calc(100% - 220px)',
        }}
      >
        {children}
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          height: '80px',
        }}
      ></div>
    </>
  );
};
