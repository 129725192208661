// @ts-nocheck

import {
  Button,
  Card,
  Col,
  Layout,
  Modal,
  notification,
  Row,
  Skeleton,
  Space,
  Table,
  Tag,
  theme,
  Typography,
} from 'antd';
import { Helmet } from 'react-helmet';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../engine/hooks/redux.hooks';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  clearUser,
  createUser,
  getUser,
  sendInvitation,
  updatePassword,
  updateUser,
} from './User.slice';
import {
  AlertOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  LeftOutlined,
  SaveFilled,
} from '@ant-design/icons';
import moment from 'moment/moment';
import Title from 'antd/es/typography/Title';
import CustomForm from '../../components/CustomForm';
import { AppContext, ContextType } from '../../engine/contexts/App.context';
import {
  listCantons,
  renderUspi,
  roleUser,
  statusUser,
  StickyWrapper,
} from '../../helpers/utils';
import dayjs from 'dayjs';
import { daysJsFormat } from '../../helpers/constants';
const { Content, Sider, Header, Footer } = Layout;

export function UserDetail({ isNew }) {
  const { logOut, user, updateUserContext } =
    useContext<ContextType>(AppContext);

  const navigate = useNavigate();
  const modalRef = useRef(null);

  const { userData, updateSuccess, createSuccess } = useAppSelector(
    (state) => state.user,
  );

  const {
    firstname = '',
    lastname = '',
    note,
    email = '',
    address: {
      longitude = '',
      latitude = '',
      street = '',
      zip = '',
      npa = '',
      number = '',
      canton = '',
      city = '',
      country = '',
      beneficiary = '',
      mobile = '',
    } = {},
    status = '',
    role = '',
    phone = '',
    birthdate = '',
    date_registration,
    company,
    uspi = [],
  } = userData || {};

  useEffect(() => {
    if (updateSuccess && user._id === userData._id) {
      updateUserContext();
    }
  }, [updateSuccess]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (createSuccess) {
      navigate(`/user/${userData._id}`, { replace: true });
    }
  }, [createSuccess]);

  const {
    token: { colorBgContainer, colorPrimary },
  } = theme.useToken();

  const formRef = useRef(null);
  const formReset = useRef(null);

  const { id: userId } = useParams();

  useEffect(() => {
    if (!isNew) {
      if (userId === undefined) {
        dispatch(getUser(user._id));
      } else {
        dispatch(getUser(userId));
      }
    }

    return () => {
      dispatch(clearUser());
    };
  }, []);

  const [loadingSvg, setLoadingSvg] = useState(false);

  const confirmCreation = (data) => {
    Modal.confirm({
      open: false,
      width: 500,
      title: 'Que souhaitez-vous faire ?',
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        dispatch(createUser(data));
      },
      footer: [
        <Space
          style={{ marginTop: 30, display: 'flex', justifyContent: 'end' }}
        >
          <Button
            size={'middle'}
            onClick={() => {
              Modal.destroyAll();
            }}
          >
            Annuler
          </Button>
          <Button
            style={{ background: colorPrimary }}
            size={'middle'}
            type={'primary'}
            onClick={() => {
              dispatch(createUser({ data, email: false }));
              Modal.destroyAll();
            }}
          >
            Créer
          </Button>
          <Button
            style={{ background: colorPrimary }}
            size={'middle'}
            type={'primary'}
            onClick={() => {
              dispatch(createUser({ data, email: true }));
              Modal.destroyAll();
            }}
          >
            Créer + email d'invitation
          </Button>
        </Space>,
      ],
      content:
        "Vous pouvez créer un utilisateur et envoyer directement un email d'invitation",
    });
  };
  const onSubmitForm = (data) => {
    // let v = formRef.current.getF

    if (isNew) {
      confirmCreation(data);
    } else {
      data._id = userData._id;

      dispatch(updateUser(data));
    }
  };

  const onSubmitForm2 = (data) => {
    // let v = formRef.current.getF
    data._id = userData._id;

    if (data.uspi === undefined) {
      const uspi = formRef2.current.getFieldValue('uspi');
      data.uspi = uspi;
    }

    dispatch(updateUser(data));
  };

  const onSubmitFormPassword = (data) => {
    if (data.password1 !== data.password2) {
      notification.error({ message: 'Mot de passe pas identique' });
    } else if (data.password1.length < 3) {
      notification.error({ message: 'Mot de passe trop court' });
    } else {
      dispatch(updatePassword({ _id: userData._id, password: data.password2 }));

      // changeAdminClientDetailPassword(
      //     data.password1,
      //     adminClientDetailData._id,
      // );
    }
  };

  const confirm = (data) => {
    Modal.confirm({
      title: 'Attention vous modifiez une donnée sensible !',
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        formReset.current.submit();
      },
      content: 'Le mot de passe sera modifié',
      okText: 'Je confirme ',
      cancelText: 'Annuler',
    });
  };

  const confirmdelete = () => {
    Modal.confirm({
      bodyStyle: { border: 'red 2px solid', borderRadius: '3px' },
      title: `⚠️⚠️ Attention ⚠️⚠️ Voulez-vous supprimer cet utilisateur ?`,
      icon: <AlertOutlined style={{ color: 'red' }} />,
      onOk: () => {},
      content:
        'Action irréversible. Cela supprimera toutes les commandes, factures et autres données de ce client.',
      okText: `Oui supprimer  ${firstname} ${lastname}`,
      cancelText: 'Annuler',
    });
  };

  const inputs = [
    {
      parentLabel: 'Informations personnelles',
      type: 'card',
      children: [
        {
          label: 'Prénom',
          name: 'firstname',
          value: firstname,
          required: true,
          capitalize: false,
          type: 'input',
          col: 12,
          offset: 0,
        },
        {
          label: 'Nom',
          name: 'lastname',
          value: lastname,
          type: 'input',
          required: true,
          col: 12,
        },
        {
          label: 'Email',
          name: 'email',
          value: email,
          required: true,
          disabled: !isNew,
          normalize: (value) => (value || '').toLowerCase(),
          type: 'email',
          col: 12,
          offset: 0,
        },
        {
          label: 'Téléphone',
          name: 'phone',
          value: phone,
          type: 'number',
          addonBefore: '+41',
          required: false,
          rules: [
            {
              pattern:
                /^\+?([1-9][0-9]{0,1})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/,
              required: false,
              message: 'Veuillez saisir votre numéro de téléphone !',
            },
          ],
          col: 12,
        },
      ],
    },
    {
      parentLabel: 'Adresse personnelle',
      type: 'card',
      children: [
        {
          label: 'Rue',
          name: 'address.street',
          value: street,
          type: 'input',
          col: 8,
        },
        {
          label: 'Numéro',
          name: 'address.number',
          value: number,
          type: 'input',
          col: 4,
        },
        {
          label: 'NPA',
          name: 'address.npa',
          value: npa,
          type: 'input',
          col: 6,
        },
        {
          label: 'Ville',
          name: 'address.city',
          value: city,
          type: 'input',
          col: 6,
        },
      ],
    },
    {
      parentLabel: 'Informations professionnelles',
      type: 'card',
      children: [
        {
          label: 'USPI',
          name: 'uspi',
          allowClear: true,
          multiple: true,
          disabled: user.role === 'user',
          value: uspi,
          type: 'select',
          selectContent: listCantons,
          col: 12,
        },
        {
          label: 'Gérances',
          name: 'company',
          disabled: user.role === 'user',
          allowClear: true,
          value: company?._id,
          type: 'dynamic-select',
          endPoint: `/companies`,

          itemCallback: (item) => {
            if (item._id === company) {
            }
            return {
              value: item._id,
              label: item.name,
            };
          },
          col: 12,
        },
        {
          label: 'Status',
          required: true,

          name: 'status',
          value: status,
          disabled: user.role === 'user',
          type: 'select',
          selectContent: statusUser,
          col: 6,
        },
        {
          label: 'Role',
          required: true,

          disabled: user.role === 'user',
          name: 'role',
          value: role,
          type: 'select',
          selectContent: roleUser,
          col: 6,
        },
      ],
    },
  ];

  const inputsConfig = [];

  const inputsPassword = [
    {
      parentLabel: 'Changer le Mot de passe',
      children: [
        {
          label: 'Nouveau mot de passe',
          name: 'password1',
          disabled: isNew,
          value: '',
          type: 'password',
          col: 11,
          offset: 0,
          required: true,
        },
        {
          label: 'Recopier le nouveau mot de passe',
          name: 'password2',
          value: '',
          disabled: isNew,
          required: true,
          type: 'password',
          col: 12,
          offset: 1,
        },
      ],
    },
  ];

  if (user === null) {
    return <Skeleton active />;
  }

  const onValuesChange = () => {};

  //initialize throttlePause variable outside throttle function
  let throttlePause;
  const throttle = (callback, time) => {
    //don't run the function if throttlePause is true
    if (throttlePause) return;
    //set throttlePause to true after the if condition. This allows the function to be run once
    throttlePause = true;

    //setTimeout runs the callback within the specified time
    setTimeout(() => {
      callback();

      //throttlePause is set to false once the function has been called, allowing the throttle function to loop
      throttlePause = false;
    }, time);
  };

  return (
    <div>
      <Helmet>
        <title>Extranet USPI - Membre</title>
      </Helmet>
      <StickyWrapper>
        <Header
          style={{
            padding: 10,
            background: colorBgContainer,
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography.Title
            style={{
              marginBottom: 0,
              marginLeft: '20px',
              color: ' rgb(0 21 41)',
              fontWeight: 300,
              fontSize: '25px',
            }}
            className={''}
          >
            {lastname} {firstname}
          </Typography.Title>
          <Row gutter={[15, 15]}>
            <Col span={24}>
              <Space>
                {/*<Link to={'/admin/clients'}>*/}
                {/*  <Button type={'primary'} icon={<LeftOutlined />}>*/}
                {/*    Retour à la liste*/}
                {/*  </Button>*/}
                {/*</Link>*/}
                <Button
                  type="primary"
                  icon={<SaveFilled />}
                  onClick={async () => {
                    try {
                      const v = await formRef.current.validateFields();
                      formRef.current.submit();
                    } catch (e) {
                      notification.error({
                        message:
                          e.errorFields.length > 1
                            ? e.errorFields.length + ' champs sont incomplets'
                            : e.errorFields.length + ' champ est incomplet',
                      });
                    }
                  }}
                >
                  {isNew ? 'Créer un nouveau utilisateur' : 'Sauvegarder'}
                </Button>
              </Space>
            </Col>
          </Row>
        </Header>
      </StickyWrapper>
      <Content
        style={{
          margin: '20px',
          overflow: 'initial',
        }}
      >
        {userData === null && !isNew ? (
          <Skeleton />
        ) : (
          <Row gutter={[15, 15]}>
            <Col span={24}>
              <CustomForm
                handleSubmit={onSubmitForm}
                dataSource={inputs}
                refForm={formRef}
                onValuesChange={onValuesChange}
                labelCol={24}
                wrapperCol={24}
              />

              <Card>
                <CustomForm
                  handleSubmit={onSubmitFormPassword}
                  dataSource={inputsPassword}
                  labelCol={24}
                  refForm={formReset}
                  wrapperCol={24}
                />
                <Button
                  disabled={isNew}
                  type="primary"
                  icon={<SaveFilled />}
                  onClick={() => {
                    confirm();
                  }}
                >
                  Sauvegarder le mot de passe
                </Button>
              </Card>

              {user.role === 'admin' && (
                <>
                  <br />
                  <Card>
                    <h3
                      className="ant-form-item-title"
                      // style={{ color: colors.blue }}
                    >
                      Envoyer un email d'invitation
                    </h3>
                    <br />
                    <Button
                      type={'primary'}
                      onClick={() => {
                        throttle(
                          () => dispatch(sendInvitation(userData._id)),
                          5000,
                        );
                      }}
                    >
                      Envoyer l'email d'invitation
                    </Button>
                  </Card>
                </>
              )}
            </Col>
          </Row>
        )}
      </Content>
    </div>
  );
}
