// @ts-nocheck

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { http } from '../../helpers/http';
import { notification } from 'antd';

interface CahiersState {
  cahiers: object[];
  templates: object[];
  loadingCahiers: boolean;
  loadingTemplates: boolean;
  companyLoading: boolean;
  company: {
    name: string;
    _id: string;
  };
}

const initialState: CahiersState = {
  cahiers: [],
  templates: [],
  loadingCahiers: false,
  loadingTemplates: false,
  companyLoading: false,
  company: null,
};

export const getCahiers = createAsyncThunk(
  'posts/getCahiers',
  async (userId, thunkAPI) => {
    const res = await http.get(`/documents/cahiers?user=${userId}`);
    return res.data;
  },
);

export const getTemplates = createAsyncThunk(
  'posts/getTemplates',
  async (userId, thunkAPI) => {
    const res = await http.get(`/documents/templates?user=${userId}`);
    return res.data;
  },
);

export const duplicate = createAsyncThunk(
  'posts/duplicate',
  async ({ id, type, userId }, thunkAPI) => {
    const res = await http.post(`/documents/duplicate`, { id, type });

    thunkAPI.dispatch(getTemplates(userId));
    thunkAPI.dispatch(getCahiers(userId));

    notification.success({
      message: `Document dupliqué`,
    });

    return res.data;
  },
);

export const deleteDocument = createAsyncThunk(
  'posts/deleteDocument',
  async ({ id, type, userId }, thunkAPI) => {
    const res = await http.post(`/documents/delete`, { id, type });

    thunkAPI.dispatch(getTemplates(userId));
    thunkAPI.dispatch(getCahiers(userId));

    notification.success({
      message: `Document supprimé`,
    });

    return res.data;
  },
);

export const newTemplate = createAsyncThunk(
  'posts/newTemplate',
  async ({ userId, name, template }, thunkAPI) => {
    const res = await http.post(`/documents/new_template`, {
      user: userId,
      name,
      template,
    });
    thunkAPI.dispatch(getTemplates(userId));
    notification.success({
      message: `Model créé`,
    });
  },
);

export const newCahier = createAsyncThunk(
  'posts/newCahier',
  async ({ userId, name, template }, thunkAPI) => {
    const res = await http.post(`/documents/new_cahier`, {
      user: userId,
      name,
      template,
    });
    thunkAPI.dispatch(getCahiers(userId));
    notification.success({
      message: `Cahier créé`,
    });
  },
);

const cahiersSlice = createSlice({
  name: 'cahiers',
  initialState,
  reducers: {},
  extraReducers: {
    [getCahiers.pending]: (state) => {
      state.loadingCahiers = true;
    },
    [getCahiers.fulfilled]: (state, { payload }) => {
      state.loadingCahiers = false;
      state.cahiers = payload;
    },
    [getTemplates.pending]: (state) => {
      state.loadingTemplates = true;
    },
    [getTemplates.fulfilled]: (state, { payload }) => {
      state.loadingTemplates = false;
      state.templates = payload;
    },
    [newTemplate.pending]: (state) => {
      state.loadingTemplates = true;
    },
    [newCahier.pending]: (state) => {
      state.loadingCahiers = true;
    },
    [duplicate.pending]: (state) => {
      state.loadingCahiers = true;
      state.loadingTemplates = true;
    },
    [duplicate.fulfilled]: (state) => {
      state.loadingCahiers = false;
      state.loadingTemplates = false;
    },
    [deleteDocument.pending]: (state) => {
      state.loadingCahiers = true;
      state.loadingTemplates = true;
    },
    [deleteDocument.fulfilled]: (state) => {
      state.loadingCahiers = false;
      state.loadingTemplates = false;
    },
  },
});

export const {} = cahiersSlice.actions;

export const cahiersReducer = cahiersSlice.reducer;
