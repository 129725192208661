// @ts-nocheck

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { notification } from 'antd';
import { http } from '../../helpers/http';
import * as timers from 'timers';
import moment from 'moment/moment';

interface CahierModelState {
  chaptersHidden: [];
  cahierModelData: object;
  cahierEntries: object;
  email: string;
  loading: boolean;
  loadingSave: boolean;
}

const initialState: CahierModelState = {
  cahierModelData: null,
  cahier: null,
  loading: false,
  loadingSave: false,
};
const keys4 = ['categories', 'chapters', 'subChapters', 'entries'];
const keys3 = ['categories', 'chapters', 'entries'];

function findObjectById(obj, levels, deps = 0) {
  if (!obj || levels.length === 0) {
    return null;
  }

  const level = levels[deps];
  const refChildren = levels.length === 4 ? keys4[deps] : keys3[deps];

  if (obj[refChildren]) {
    const nextObj = obj[refChildren].find((r) => r._id === level);
    if (deps === 3 && levels.length === 4) {
      return nextObj;
    }

    if (deps === 2 && levels.length === 3) {
      return nextObj;
    }

    return findObjectById(nextObj, levels, deps + 1);
  }

  return null;
}

// Usage

export const getCahierModel = createAsyncThunk(
  'cahierModels/getCahierModel',
  async (cahierModelId: String, thunkAPI) => {
    const res = await http.get('/documents/' + cahierModelId);
    return res.data;
  },
);

export const getCahierEntries = createAsyncThunk(
  'cahierModels/getCahierEntries',
  async (cahierId: String, thunkAPI) => {
    const res = await http.get('/documents/cahiers/' + cahierId);
    return res.data;
  },
);

export const updateCahierModel = createAsyncThunk(
  'cahierModels/update',
  async (cahierModel: CahierModelState, thunkAPI) => {
    const res = await http.patch('/documents/' + cahierModel._id, cahierModel);
    return res.data;
  },
);

export const updateCahier = createAsyncThunk(
  'cahierModels/updateCahier',
  async (cahier, thunkAPI) => {
    const res = await http.patch('/documents/cahiers/' + cahier._id, cahier);
    return res.data;
  },
);

const cahierModelSlice = createSlice({
  name: 'cahierModel',
  initialState,
  reducers: {
    clearCahierModel: (state) => {
      return initialState;
    },
    updateEntryValue: (state, { payload }) => {
      const { levels, type, value } = payload;
      let copy = state.cahierModelData;

      const foundObject = findObjectById(copy, levels);

      foundObject[type] = value;
      state.cahierModelData = copy;
    },
    updatePrestation: (state, { payload }) => {
      const { entry, shown, note } = payload;
      let copy = state.cahier;
      let find = copy.prestations.find((e) => e.entry === entry);

      if (find === undefined) {
        copy.prestations.push({
          entry,
          shown: shown,
          note,
        });
      } else {
        find.note = note;
        find.shown = shown;
      }

      state.cahier = copy;
    },
    updateChapterShwon: (state, { payload }) => {
      if (payload.shown) {
        // Remove the chapterId from the chaptersHidden array if it exists
        state.cahier.chaptersHidden = state.cahier.chaptersHidden.filter(
          (id) => id !== payload.chapterId,
        );
      } else {
        // Add the chapterId to the chaptersHidden array if it doesn't already exist
        if (!state.cahier.chaptersHidden.includes(payload.chapterId)) {
          state.cahier.chaptersHidden.push(payload.chapterId);
        }
      }
    },
    updatePrestationsShown: (state, { payload }) => {
      const { entries, shown, note = '' } = payload;

      let copy = state.cahier;

      entries.map((entry) => {
        let find = copy.prestations.find((e) => e.entry === entry);

        if (find === undefined) {
          copy.prestations.push({
            entry,
            shown: shown,
            note,
          });
        } else {
          find.shown = shown;
        }
      });

      state.cahier = copy;
    },
  },
  extraReducers: {
    [getCahierModel.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getCahierModel.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.cahierModelData = payload;
    },
    [updateCahierModel.pending]: (state, { payload }) => {
      state.loadingSave = true;
    },
    [updateCahier.pending]: (state, { payload }) => {
      state.loadingSave = true;
    },
    [getCahierEntries.pending]: (state, { payload }) => {
      // state.loadingSave = true;
    },
    [getCahierEntries.fulfilled]: (state, { payload }) => {
      // state.loadingSave = true;
      state.cahier = payload;
    },
    [updateCahierModel.fulfilled]: (state, { payload }) => {
      // state.cahierModelData = payload;
      state.loadingSave = false;
      notification.success({
        message: `Model modifié`,
      });
    },
    [updateCahier.fulfilled]: (state, { payload }) => {
      // state.cahierModelData = payload;
      state.loadingSave = false;
      notification.success({
        message: `Cahier modifié`,
      });
    },
    [getCahierModel.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const {
  updateChapterShwon,
  clearCahierModel,
  updatePrestation,
  updatePrestationsShown,
  updateEntryValue,
} = cahierModelSlice.actions;

export const cahierModelReducer = cahierModelSlice.reducer;
